import {ReactSVG} from "react-svg";
import deleteSvg from '../../../images/catalogs/category/delete.svg'
import {useDispatch, useSelector} from "react-redux";
import styles from "./style.module.scss";

const DeleteCategory = () => {

	const dispatch = useDispatch();
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId)

	return(
		<div className={`${styles.catalogToolsItem} ${!currentCategoryId ? styles.disabled : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Удалить категорию'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type:'SWITCH_POPUP', popupType: 'deleteCatalogCategory', popupIsOpen: true})}
		>
			<ReactSVG src={`${deleteSvg}`} />
		</div>
	)
}

export default DeleteCategory;