import ButtonLoader from "../pages/main/components/ButtonLoader";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import CustomInput from "../pages/main/components/CustomInput";
import SelectInput from "../pages/main/components/SelectInput";
import {api} from "../api";
import {useDispatch} from "react-redux";

const AddPriceTypeModal = () => {
	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')

	const [code, setCode] = useState('')
	const [name, setName] = useState('')

	const [selectedDealers, setSelectedDealers] = useState([])
	const [dealerList, setDealerList] = useState(null)

	const dispatch = useDispatch();

	//Нужно для перевода
	const {t} = useTranslation();

	const checkBoxHandler = (value) => {
		// Создаем копию текущего массива selectedDealers
		const updatedSelectedDealers = [...selectedDealers];

		if (value.isChecked) {
			// Добавляем значение в массив, если чекбокс отмечен
			updatedSelectedDealers.push(value.value);
		} else {
			// Удаляем значение из массива, если чекбокс не отмечен
			const index = updatedSelectedDealers.indexOf(value.value);
			if (index > -1) {
				updatedSelectedDealers.splice(index, 1);
			}
		}

		// Обновляем состояние selectedDealers с новым массивом
		setSelectedDealers(updatedSelectedDealers);
	};

	//Функция отправки формы
	const sendForm = async () => {
		setLoad(true)
		try {
			const body = {
				code: code,
				name: name,
				access: selectedDealers
			}
			const addPriceTypeResponse = await api.cabinetApi.addPriceType(body);

			if (addPriceTypeResponse.status === true) {
				const priceType = await api.cabinetApi.getPriceType();
				if (priceType.status === true) {
					dispatch({type: "SET_PRICE_TYPES", priceTypes: priceType.pricetypes})
				}
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

			} else {
				setError(addPriceTypeResponse.message)
			}

		} catch (e) {
			console.log(e)
		} finally {
			setLoad(false)
		}
	}

	//Получаем данные с сервера
	const getData = async () => {
		try {
			const dealerListForm = await api.cabinetApi.getDealerListForm();
			setDealerList(dealerListForm.dealers)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<div className={'i_add-price-modal'}>
			<div className="i_add-price-modal-title">
				<span>Новый тип цены</span>
			</div>
			<div className="i_add-price-modal-items">
				<CustomInput title={'Код'} value={code} onChange={(e) => {
					setCode(e)
				}}/>
				<CustomInput title={'Название'} value={name} onChange={(e) => {
					setName(e)
				}}/>
			</div>

			<div className="i_add-price-dealers">
				<div className="i_add-price-dealers-title">
					<span>Список дилеров</span>
				</div>
				<div className="i_add-price-dealers-items">
					{
						dealerList && dealerList.map((item, index) => {
							return (
								<SelectInput title={item.name} value={item.id} checkBoxValue={checkBoxHandler} key={index}/>
							)
						})
					}
				</div>
			</div>

			<div className="i_add-price-modal-btn">
				<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')} onClick={sendForm}/>
			</div>

			{error !== '' ?
				<div className={`i_modal-error`}>
					<span>{error}</span>
				</div> : ''}
		</div>

	)
}

export default AddPriceTypeModal;