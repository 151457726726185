import {useSelector} from "react-redux";
import AddMyCategory from "../multitools/components/AddMyCategory";
import AddCategory from "../multitools/components/AddCategory";
import AddSourceCategory from "../multitools/components/AddSourceCategory";
import EditCategory from "../multitools/components/EditCategory";
import EditSourceCategory from "../multitools/components/EditSourceCategory";
import EditMyCategory from "../multitools/components/EditMyCategory";

const EditCategoryModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)

	return (
		<>
			{currentTools === "catalogs-my-inner" && <EditMyCategory />}
			{currentTools === "catalogs-source-inner" && <EditSourceCategory />}
			{currentTools === "distributor-catalogs-inner" && <EditCategory />}
		</>
	)
}

export default EditCategoryModal