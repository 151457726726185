import {useSelector} from "react-redux";
import {TOOLS_CONFIG} from "../../../utils/config";

import styles from './CatalogTools.module.scss'

const CatalogTools = ({type}) => {

	// Получаем текущий мультитул из стейта
	const currentTools = useSelector(state => state.multiTools.currentTools)

	// Если текущий мультитул есть, то рендерим его
	return (
		<div className={`${styles.catalogTools} ${styles[type]} ${!currentTools ? styles.hide : ''}`}>
			{currentTools && (
				TOOLS_CONFIG.pages[currentTools].catalogTools.components.map((Component, index) => {
					return <Component key={index}/>
				})
			)}
		</div>
	)
}

export default CatalogTools;