import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import infoImg from "../../images/info.svg";
import {api} from "../../api";

const SelectProductList = ({store}) => {
	const dispatch = useDispatch();
	const nextOffset = store.nextOffset;
	const currentCategoryId = store.currentCategoryId;
	const productList = store.productList;
	const [showMore, setShowMore] = useState(true);
	const catalogCode = store.catalogCode;

	useEffect(() => {
		if (nextOffset === null || nextOffset === 'undefined') {
			setShowMore(false)
		}else if(nextOffset >= 100){
			setShowMore(true)
		}
	}, [nextOffset])

	const handleEditSelectedItem = (event, id) => {
		event.stopPropagation();

		const updatedItem = productList.map((item) => {
			if(item.id === id){
				item.flag_select = !item.flag_select
			}

			return item
		})

		const selectedIds = updatedItem
			.filter(item => item.flag_select)   // выбираем только элементы с flag_select === true
			.map(item => item.id);              // извлекаем их id

		dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: selectedIds});
		dispatch({type: "SET_MARKUPS_PRODUCT", productList: updatedItem});
	}


	//Функция для подгрузки товаров
	const loadMore = async () => {
		try {

			console.log(nextOffset)

			const productList = await api.cabinetApi.getCatalogProductList({
				code: catalogCode,
				offset: nextOffset,
				filter: {"category_id": currentCategoryId}
			});

			dispatch({
				type: "GET_MARKUPS_MORE_PRODUCT_LIST",
				productList: productList.products,
				nextOffset: productList.next_offset
			})
			if (!productList.next_offset) {
				setShowMore(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<div className="i_catalog-product-head">
				<div className="i_catalog-product-head-item name">
					<span>Название</span>
				</div>
				<div className="i_catalog-product-head-item article">
					<span>Артикул</span>
				</div>
				<div className="i_catalog-product-head-item inner_code">
					<span>Внутренний код</span>
				</div>
			</div>
			<div className="i_catalog-product-items">
				{productList?.length === 0 && (
					<div className="i_catalog-product-empty">
						<div className="i_catalog-page-empty-title">
							<img src={infoImg} alt=""/>
							<span>Товары отсутствуют.</span>
						</div>
					</div>
				)}
				{
					productList?.length > 0 ? productList.map((product, index) => {
						return (
							<div className={`i_catalog-product-item`} key={product.id}>
								<div className="i_catalog-product-item-name">
									<span>{product.name}</span>
								</div>
								<div className="i_catalog-product-item-article">
									<span>{product.vendor_code}</span>
								</div>
								<div className="i_catalog-product-item-inner_code">
									<span>{product.inner_code}</span>
								</div>
								<div className={`i_catalog-product-item-select ${product?.flag_select ? 'selected' : ''}`} onClick={(event) => {
									handleEditSelectedItem(event, product.id)
								}}>
									<span>{product?.flag_select ? 'выбрано' : 'выбрать'}</span>
								</div>
							</div>

						);
					}) : ''
				}
				{showMore && productList.length > 0 && (
					<div className={'i_catalog-product-more'}>
						<span onClick={() => {
							loadMore()
						}}>Ещё</span>
					</div>
				)}
			</div>

		</>
	)
}

export default SelectProductList;