import ButtonLoader from "../pages/main/components/ButtonLoader";
import {useEffect, useState} from "react";
import CustomInput from "../pages/main/components/CustomInput";
import SelectInput from "../pages/main/components/SelectInput";
import {api} from "../api";
import {useDispatch, useSelector} from "react-redux";

const EditPriceTypeModal = () => {
	//Достаем данные из стейта для использования в компоненте
	const dispatch = useDispatch();
	const priceType = useSelector(state => state.settings.priceTypes)
	const currentPriceTypeCode = useSelector(state => state.multiModal.modals[0].modalData.currentPriceTypeItem)
	const currentPriceTypeName = useSelector(state => state.multiModal.modals[0].modalData.currentPriceTypeName)

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [name, setName] = useState(currentPriceTypeName)
	const [selectedDealers, setSelectedDealers] = useState([])
	const [dealerList, setDealerList] = useState(null)

	const checkBoxHandler = (value) => {
		// Создаем копию текущего массива selectedDealers
		const updatedSelectedDealers = [...selectedDealers];

		if (value.isChecked) {
			// Добавляем значение в массив, если чекбокс отмечен
			updatedSelectedDealers.push(value.value);
		} else {
			// Удаляем значение из массива, если чекбокс не отмечен
			const index = updatedSelectedDealers.indexOf(value.value);
			if (index > -1) {
				updatedSelectedDealers.splice(index, 1);
			}
		}

		// Обновляем состояние selectedDealers с новым массивом
		setSelectedDealers(updatedSelectedDealers);
	};

	//Функция отправки формы
	const sendForm = async () => {
		setLoad(true)
		try{
			const body = {
				name: name,
				access: selectedDealers,
				code: currentPriceTypeCode
			}
			const addPriceTypeResponse = await api.cabinetApi.editPriceType(body);

			if(addPriceTypeResponse.status === true){
				const priceType = await api.cabinetApi.getPriceType();
				if(priceType.status === true) {
					dispatch({type: "SET_PRICE_TYPES", priceTypes: priceType.pricetypes})
				}
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

			}else{
				setError(addPriceTypeResponse.message)
			}

		}catch (e){
			console.log(e)
		}finally {
			setLoad(false)
		}
	}

	//Получаем данные с сервера
	const getData = async () => {
		try{
			const dealerListForm = await api.cabinetApi.getDealerListForm();
			setDealerList(dealerListForm.dealers)


			//Записываем данные в state из redux для показа уже подключенных дилеров и добавляем в выбранных дилеров только тех которые есть в списке доступа
			priceType.forEach((item) =>{
				if(item.ext_id === currentPriceTypeCode){
					if(item.access && item.access.length > 0){
						const dealers = item.access
						const selectedDealers = []
						//Форычим, для того чтобы получить id дилеров и проверить что они есть в списке доступа
						dealerListForm.dealers.forEach((dealer) => {
							if(dealers.includes(dealer.id)){
								selectedDealers.push(dealer.id)
							}
						})
						setSelectedDealers(selectedDealers)
					}
				}
			})

		}catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		//Здесь получаем список дилеров
		getData()
	},[])

	return(
		<div className={'i_add-price-modal'}>
			<div className="i_add-price-modal-title">
				<span>Редактирование типа цены</span>
			</div>
			<div className="i_add-price-modal-code">
				<span>Код: {currentPriceTypeCode}</span>
			</div>
			<div className="i_add-price-modal-items">
				<CustomInput title={'Название'} value={name} onChange={(e) => {setName(e)}} />
			</div>

			<div className="i_add-price-dealers">
				<div className="i_add-price-dealers-title">
					<span>Список дилеров</span>
				</div>
				<div className="i_add-price-dealers-items">
					{
						dealerList && dealerList.map((item, index) => {
							return (
								<SelectInput title={item.name} value={item.id} checkBoxValue={checkBoxHandler} inputChecked={selectedDealers?.includes(item.id)} key={index}/>
							)
						})
					}
				</div>
			</div>

			<div className="i_add-price-modal-btn">
				<ButtonLoader load={load} title={'Изменить'} onClick={sendForm}/>
			</div>

			{error !== '' ?
				<div className={`i_modal-error`}>
					<span>{error}</span>
				</div> : ''}
		</div>

	)
}

export default EditPriceTypeModal;