import styles from './Tabs.module.scss'
import {useState} from "react";

const Tabs = ({items, onChange, currentTab}) => {

	function changeCurrentTab(e){
		onChange(e)
	}

	return(
		<div className={styles.tabs}>
			<div className={`${styles.tab} ${currentTab === null ? styles.active : ''}`} onClick={()=>{changeCurrentTab(null)}}>
				<span>Все</span>
			</div>
			{
				items.map(item => {
					return(
						<div className={`${styles.tab} ${currentTab === item.id ? styles.active : ''}`} key={item.id} onClick={()=>{changeCurrentTab(item.id)}}>
							<span>{item.name}</span>
						</div>
					)
				})
			}
		</div>
	)
}

export default Tabs;