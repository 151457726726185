import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";

const DeletePriceType = () => {

	const dispatch = useDispatch();
	const currentPriceTypeItem = useSelector(state => state.multiPopup.currentPriceTypeItem);

	const deletePriceType = async () => {
		try{
			const response = await api.cabinetApi.deletePriceType({code: currentPriceTypeItem});
			if(response.status === true){
				const priceType = await api.cabinetApi.getPriceType();
				if(priceType.status === true) {
					dispatch({type: "SET_PRICE_TYPES", priceTypes: priceType.pricetypes})
				}
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentPriceTypeItem: null})
			}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>С типом цены будут удалены все цены товаров привязанные к нему<br/><br/>Вы действительно хотите удалить тип цены?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={()=>{deletePriceType()}}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentPriceTypeItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeletePriceType;