import closeProfile from "../../../../images/closeModal.svg";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import styles from './Company.module.scss';

const Company = () => {
	const userData = useSelector(state => state.userData.data)
	const role = useSelector(state => state.userData.data?.entity.type)
	const companyRef = useRef(null)
	const companyHeadRef = useRef(null)
	const companyMoreRef = useRef(null)
	const [companyActive, setCompanyActive] = useState(false)
	const [companyHeight, setCompanyHeight] = useState(null)
	const [companyWidth, setCompanyWidth] = useState(null)

	// useEffect для получения высоты Company
	useEffect(() => {
		calcHeightAndWidth()
	}, [companyActive]);

	useEffect(() => {
		// Добавляем слушатель события при монтировании компонента
		window.addEventListener('resize', calcHeightAndWidth);

		// Убираем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener('resize', calcHeightAndWidth);
		};
	}, []);

	function calcHeightAndWidth(){
		if (companyMoreRef.current) {
			const blockHeight = companyMoreRef.current.clientHeight;
			setCompanyHeight(blockHeight);
		}
		if(companyHeadRef.current){
			setTimeout(() => {
				const blockWidth = companyHeadRef.current.clientWidth;
				setCompanyWidth(blockWidth);
			},200)
		}
	}

	// useEffect для обработки клика за пределами компонента
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if(companyRef.current && !companyRef.current.contains(event.target)){
				setCompanyActive(false)
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			setCompanyActive(false)
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<div className={`${styles.headerItem} ${styles.companyName} ${companyActive ? styles.active : ''}`} ref={companyRef}
	     style={{
		     minWidth: `${companyWidth + 30}px`
	     }}
		>
			<div className={styles.headerItemCompany} style={{
				height: `${companyActive ? companyHeight + 70 : 36}px`,
				borderRadius: `${companyActive ? 15 : 80}px`,
				minWidth: `${companyActive ? companyWidth + 75 : companyWidth + 30}px`,
			}}>
				<div className={styles.companyHead}>
					<div className={styles.companyHeadLeft} onClick={() => {
						setCompanyActive(true)
					}} ref={companyHeadRef}>
						<span className={styles.companyNameTitle}>
							{userData?.entity.name}
						</span>
						<span className={styles.companyRoleTitle}>
							<span className={styles.circle}></span>
							{role === 'distributor' ? 'дистрибьютор' : 'дилер'}
						</span>
					</div>
					<div className={styles.companyClose} onClick={() => {
						setCompanyActive(false)
					}}>
						<img src={`${closeProfile}`} alt="close"/>
					</div>
				</div>
				<div className={styles.companyMore} ref={companyMoreRef}>
					<div className={styles.companyMoreItem}>
						<span className={styles.dotted}></span>
						<Link className={styles.itemValue} to={'/profile/'} onClick={() => {
							setCompanyActive(false)
						}}><span>Профиль</span></Link>
					</div>
					<div className={styles.companyMoreItem}>
						<span className={styles.dotted}></span>
						<Link className={styles.itemValue} to={'/billing/'} onClick={() => {
							setCompanyActive(false)
						}}><span>Биллинг</span></Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Company;