export default function getDeclension(number, wordForms) {
	number = Math.abs(number) % 100;
	const lastDigit = number % 10;

	if (number > 10 && number < 20) {
		return wordForms[2];
	}
	if (lastDigit > 1 && lastDigit < 5) {
		return wordForms[1];
	}
	if (lastDigit === 1) {
		return wordForms[0];
	}
	return wordForms[2];
}