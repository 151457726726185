import {api} from "../../../../api";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import Skeleton from "react-loading-skeleton";
import "../styles/settingsPage.scss"
import {ReactSVG} from "react-svg";

import eyeOpen from "../../../../images/eye.svg"
import eyeClose from "../../../../images/eye-close.svg"

const SettingsPage = () => {
	const {t} = useTranslation();

	//Храним api ключ
	const [apiKey, setApiKey] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [showApiKey, setShowApiKey] = useState(false)

	//Ассинхронная функция получения Api ключа с сервера
	const getApi = async () => {
		try{
			const apiKey = await api.cabinetApi.getApiKeys();

			if(apiKey.status === false){
				setApiKey(t('I_API_KEY_NOT_FOUND'))
				setShowApiKey(true)
			}else if(apiKey.status === true){
				setApiKey(apiKey.key)
			}

			setIsLoading(false)

		}catch (err){
			console.log(err)
		}
	}

	const generateApiKey = async () => {
		try{
			const result = await api.cabinetApi.generateApiKey();
			if(result.status === false){
				setApiKey(t('I_API_KEY_NOT_FOUND'))
			}else if(result.status === true){
				setShowApiKey(true)
				setApiKey(result.key)
			}
		}catch (err){
			console.log(err)
		}
	}

	const switchShowApiKey = () => {
		setShowApiKey(!showApiKey)
	}

	useEffect(()=>{
		getApi()
	},[])
	return (
		<div className="i_settings-page">
			{isLoading && (
				<>
					<Skeleton
						count={1}
						height={'14rem'}
						width={'100%'}
						inline={true}
						borderRadius={20}
					/>
				</>
			)}
			{!isLoading && (
				<div className="i_settings-content">
					<div className="i_settings-items">
						<div className="i_settings-item">
							<div className="i_settings-item-title">
								<span>{t('I_API_KEY')}</span>
							</div>
							<div className="i_settings-item-value">
								<span>{showApiKey ? apiKey : '##############'}</span>
								<ReactSVG src={showApiKey ? eyeOpen : eyeClose} className={'i_settings-item-showkey'} onClick={switchShowApiKey}/>
								<CopyToClipboardButton textToCopy={apiKey}/>
							</div>
							<div className="i_settings-item-button" onClick={generateApiKey}>
								<span>{t("I_API_KEY_BTN")}</span>
							</div>

							<div className="i_setting-item-alert">
								<span>! {t('I_API_KEY_ALERT')}</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SettingsPage;