const OrdersTab = () => {
	return(
		<div className={'i_orders-tabs'}>
			<div className={'i_orders-tabs-items'}>
				<div className="i_orders-tabs-item id">
					<span>№</span>
				</div>
				<div className="i_orders-tabs-item client">
					<span>Клиент</span>
				</div>
				<div className="i_orders-tabs-item date">
					<span>Дата обновления</span>
				</div>
				<div className="i_orders-tabs-item status">
					<span>Статус</span>
				</div>
				<div className="i_orders-tabs-item distributors">
					<span>Дистрибьюторы</span>
				</div>
				<div className="i_orders-tabs-item products">
					<span>Товары</span>
				</div>
				<div className="i_orders-tabs-item summary">
					<span>Итого</span>
				</div>
			</div>
		</div>
	)
}

export default OrdersTab;