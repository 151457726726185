const initialState = {
	profileData: {}
}

export const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_PROFILE_DATA":
			return {
				...state,
				profileData: action.profileData
			};
		default:
			return state;
	}
}