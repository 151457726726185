import {ReactSVG} from "react-svg";
import edit from '../../../images/catalogs/attach.svg'
import {useDispatch, useSelector} from "react-redux";

import styles from './style.module.scss'

const AttachCategory = () => {

	const dispatch = useDispatch();
	const checkedProductList = useSelector(state => state.catalog.list?.products.checkedProductId);

	return (
		<>
			<div className={`${styles.multitoolsItem} ${styles.attachItem} ${checkedProductList?.length <= 0 ? styles.disabled : ''}`}
			     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Привязать к категории'})}
			     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
			     onClick={() => checkedProductList?.length > 0 ? dispatch({type: "OPEN_MODAL", modalType: 'attachCategoryModal', modalLevel: 1}) : ''}
			>
				<ReactSVG src={`${edit}`} className={'i_multitools-item-svg'}/>
			</div>
		</>
	)
}

export default AttachCategory;