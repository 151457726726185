import {useSelector} from "react-redux";
import AddMarkupElement from "../multitools/components/AddMarkupElement";


const AddElementModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)

	return (
		<>
			{currentTools === "markups-my-catalog" && <AddMarkupElement/>}
		</>
	);
}

export default AddElementModal