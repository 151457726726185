import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

// Рекурсивный компонент для отображения категорий
const Category = ({category, categories, editList, closeCategoryList}) => {
	// Находим подкатегории для текущей категории
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level;
	const currentCatalogItem = useSelector(state => state.multiModal.modals[0].currentCategoryId);
	const lastChild = !category.has_children;


	const changeCurrentCategory = (item) => {
		if (item.id === currentCatalogItem) {
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_ID', modalLevel: 1, currentCategoryId: null});
		} else {
			dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_ID', modalLevel: 1, currentCategoryId: item.id});
			if(editList){
				closeCategoryList(item)
			}
		}
	};

	function changeSelectedItem(id) {
		let updatedItem = categories.map((item) => {
			if (item.id === id) {
				item.is_active = true;
				changeCurrentCategory(item)
			} else {
				item.is_active = false;
			}

			return item
		})

		dispatch({type: "SET_MODAL_DATA", modalLevel: 1, modalData: {categoryList: updatedItem}});
	}

	useEffect(() => {
		if (editList) {
			// Прокручиваем каталог к целевому элементу
			setTimeout(() => {
				const catalogSourceItemsBlock = document.querySelector('.i_add-category-list')
				const catalogSourceItems = catalogSourceItemsBlock.querySelector('.i_catalog-title-circle.active').closest('.i_catalog-category-item')
				catalogSourceItems.scrollIntoView({block: "start"})
			}, 250)
		}

	}, []);


	return (
		<div
			className={`i_catalog-category-item active i_catalog-category-item-level-${level} ${lastChild ? 'last-child' : ''}`}
			key={category.id}
			data-id={category.id}
			style={{paddingLeft: level === 0 ? '15px' : '30px'}}
		>
			<div className="i_catalog-category-item-title" onClick={() => changeSelectedItem(category.id)}>
				<div className={`i_catalog-title-circle ${currentCatalogItem === category.id ? 'active' : ''}`}></div>
				<span>{category.name}</span>
			</div>
			{
				category.id !== 0 && (
					subCategories.map(subCategory => (
						<Category key={subCategory.id} category={subCategory} categories={categories} editList={editList} closeCategoryList={closeCategoryList}/>
					))
				)
			}
		</div>
	);
};

const CategoryListNew = ({editList, closeCategoryList}) => {
	// Находим корневые категории (parent_id === 0)
	const categories = useSelector(state => state.multiModal.modals[0].modalData?.categoryList);
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId)

	const dispatch = useDispatch();

	useEffect(() => {
		//Показываем родителя текущей категории
		if (editList) {
			categories.forEach(category => {
				if (category.id === currentCategoryId) {
					dispatch({type: 'SET_MODAL_CURRENT_CATEGORY_ID', modalLevel: 1, currentCategoryId: category.parent_id});
				}
			})
		}
	}, []);


	const rootCategories = categories.filter(category => category.parent_id === 0 || category.id === 0);


	return (
		<div>
			{rootCategories.map(category => (
				<Category key={category.id} category={category} categories={categories} editList={editList} closeCategoryList={closeCategoryList}/>
			))}
		</div>
	);
};

export default CategoryListNew;


