import OrdersTab from "../../components/dealer/ordersPage/OrdersTab";
import OrdersItems from "../../components/dealer/ordersPage/OrdersItems";

import '../styles/ordersPage.scss'

const ordersPage = () => {
	return(
		<div className={'i_orders'}>
			<OrdersTab/>
			<OrdersItems />
		</div>
	)
}

export default ordersPage;