import React, { useState } from 'react';
import styles from './SelectInput.module.scss';

const SelectInput = ({ value, title, checkBoxValue, inputChecked, onlyOne }) => {
	const [isChecked, setIsChecked] = useState(!!inputChecked);

	const handleInputChange = () => {
		if (onlyOne) {
			// Передаем ID активного чекбокса в родительский компонент
			checkBoxValue(value);
		} else {
			// Обновляем состояние isChecked при изменении чекбокса
			const newCheckedValue = !isChecked;
			setIsChecked(newCheckedValue);

			// Если новое значение isChecked равно true, выводим значение в консоль
			checkBoxValue({ value: value, isChecked: newCheckedValue });
		}
	};

	return (
		<div className={styles.iSelectInputBox}>
			<label className={styles.iSelectContain}>
				{title && (
					<span>{title}</span>
				)}
				<input
					type="checkbox"
					value={value}
					checked={inputChecked}
					onChange={handleInputChange}
				/>
				<div className={styles.iSelectInput}></div>
			</label>
		</div>
	);
};

export default SelectInput;
