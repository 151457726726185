const initialState = {
	currentSourceItem : null,
	currentCatalogItem : null,
	currentSourceList : null,
	currentCatalogList : null,
	categorySourceList : null,
	categoryCatalogList : null,
	sourceProductList : null,
	catalogProductList: null,
	currentSourceCategoryId : null,
	currentCatalogCategoryId : null,
	showSourceProductList : null,
	showCatalogProductList: null,
	currentCatalogCategoryMatchId: null
}

export const matchingReducer = (state = initialState, action) => {
	switch (action.type) {
		case "EDIT_SOURCE_ITEM":
			return {
				...state,
				currentSourceItem: action.currentSourceItem
			};
		case "EDIT_CATALOG_ITEM":
			return {
				...state,
				currentCatalogItem: action.currentCatalogItem
			};
		case "EDIT_SOURCE_LIST":
			return {
				...state,
				currentSourceList: action.currentSourceList
			};
		case "EDIT_CATALOG_LIST":
			return {
				...state,
				currentCatalogList: action.currentCatalogList
			};
		case "EDIT_CATEGORY_SOURCE_LIST":
			return {
				...state,
				categorySourceList: action.categorySourceList
			};
		case "EDIT_CATEGORY_CATALOG_LIST":
			return {
				...state,
				categoryCatalogList: action.categoryCatalogList
			};
		case "CLEAR_MATCHING":{
			return {
				...state,
				currentSourceItem: null,
				currentCatalogItem: null,
				currentSourceList: null,
				currentCatalogList: null,
				categorySourceList: null,
				categoryCatalogList: null,
				sourceProductList: null,
				catalogProductList: null
			};
		}
		case "CLEAR_MATCHING_CURRENT" : {
			return {
				...state,
				currentSourceItem: null,
				currentCatalogItem: null,
				categorySourceList: null,
				categoryCatalogList: null
			}
		}
		case "CHANGE_SOURCE_PRODUCT_LIST":
			return{
				...state,
				sourceProductList: action.sourceProductList
			}
		case "CHANGE_CATALOG_PRODUCT_LIST":
			return {
				...state,
				catalogProductList: action.catalogProductList
			}
		case "CHANGE_SOURCE_CATEGORY_ID":
			return {
				...state,
				currentSourceCategoryId: action.currentSourceCategoryId
			}
		case "CHANGE_CATALOG_CATEGORY_ID":
			return {
				...state,
				currentCatalogCategoryId: action.currentCatalogCategoryId
			}
		case "CHANGE_SHOW_SOURCE_PRODUCT_LIST":
			return{
				...state,
				showSourceProductList: action.showSourceProductList
			}
		case "CHANGE_SHOW_CATALOG_PRODUCT_LIST":
			return{
				...state,
				showCatalogProductList: action.showCatalogProductList
			}
		case "CHANGE_CATALOG_CATEGORY_MATCH_ID":
			return{
				...state,
				currentCatalogCategoryMatchId: action.currentCatalogCategoryMatchId
			}
		default:
			return state;
	}
}