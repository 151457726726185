import selectSvg from '../../../images/select-button.svg'
import {ReactSVG} from "react-svg";

const SelectButton = ({title, onClick}) => {
	return (
		<button className={`i_select-button`} onClick={onClick}>
			<ReactSVG src={selectSvg} alt="select" className={'i_select-button-svg'}/>
			<span>{title}</span>
		</button>
	)
}

export default SelectButton