import {createStore, combineReducers} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";

import {modalReducer} from "./modalReducer";
import {popupReducer} from "./popupReducer";
import {userDataReducer} from "./userDataReducer";
import {notificationReducer} from "./notificationReducer";
import {appDataReducer} from "./appDataReducer";
import {profileReducer} from "./profileReducer";
import {settingsReducer} from "./settingsReducer";
import {matchingReducer} from "./matchingReducer";
import {cursorHelpReducer} from "./cursorHelpReducer";
import {multitoolsReducer} from "./multitoolsReducer";
import {catalogReducer} from "./catalogReducer";
import {menuReducer} from "./menuReducer";
import {uploadImageReducer} from "./uploadImageReducer";
import {markupsReducer} from "./markupsReducer";
import {noticeReducer} from "./noticeReducer";

const rootReducer = combineReducers({
	multiModal: modalReducer,
	multiPopup: popupReducer,
	userData : userDataReducer,
	notification : notificationReducer,
	appData : appDataReducer,
	profile: profileReducer,
	settings: settingsReducer,
	matching: matchingReducer,
	cursorHelp: cursorHelpReducer,
	catalog : catalogReducer,
	multiTools : multitoolsReducer,
	menu : menuReducer,
	uploadImage: uploadImageReducer,
	markups: markupsReducer,
	notice: noticeReducer
});

export const store = createStore(rootReducer, composeWithDevTools()); 