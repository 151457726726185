import {useSelector} from "react-redux";
import {TOOLS_CONFIG} from "../../utils/config";

const MultiTools = () => {

	// Получаем текущий мультитул из стейта
	const currentTools = useSelector(state => state.multiTools.currentTools)
	const activeMultiTools = TOOLS_CONFIG.pages[currentTools]?.multiTools.components.length > 0

	// Если текущий мультитул есть, то рендерим его
	return (
		<div className={`i_multitools ${!activeMultiTools ? 'hide' : ''}`}>
			{activeMultiTools && (
				TOOLS_CONFIG.pages[currentTools].multiTools.components.map((Component, index) => {
					return <Component key={index}/>
				})
			)}
		</div>
	)
}

export default MultiTools;