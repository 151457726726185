import CatalogTools from "../../../components/CatalogTools/CatalogTools";
import OpenCatalogs from "../OpenCatalogs/OpenCatalogs";
import CloseCatalogs from "../CloseCatalogs/CloseCatalogs";
import SourceCategoryList from "../dealer/catalogPage/SourceCategoryList";
import infoImg from "../../../../images/info.svg";
import styles from './CategoryList.module.scss'
import CatalogListItem from "../../../../utils/ui/CatalogListItem/CatalogListItem";
import {ScrollBox} from "../../../../utils/ui";

const CategoryList = ({category, mobile, type}) => {
	return (
		<div className={`${styles.catalogCategory} ${mobile ? styles.mobile : ''}`}>
			<div className={styles.catalogTools}>
				<CatalogTools type={'catalog'}/>
				<div className={styles.catalogToolsTight}>
					<OpenCatalogs/>
					<CloseCatalogs/>
				</div>
			</div>
			<div className={styles.catalogCategoryItems}>
				{
						category.length > 0 && (
						<ScrollBox>
							<CatalogListItem categories={category} type={type} actionType={'catalogs'}/>
						</ScrollBox>
						)
					}
				{
					category.length === 0 && (
						<div className="i_catalog-category-empty">
							<div className="i_catalog-page-empty-title">
								<img src={infoImg} alt=""/>
								<span>Категории отсутствуют.</span>
							</div>
							<span>Вы можете добавить категории через&nbsp;
								<a href="https://docs.apicore.kz/dealer-api/"
								   target="_blank">Dealer API</a>
										</span>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default CategoryList;