const initialState = {
	noticeList : [],
	offset: 0,
	noticeCount: null
}

export const noticeReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_NOTICE_LIST":
			return {
				...state,
				noticeList: action.noticeList
			}
		case "ADD_NOTICE_LIST":
			return {
				...state,
				noticeList: [...state.noticeList, ...action.noticeList]
			}
		case "SET_NOTICE_OFFSET":
			return {
				...state,
				offset: action.offset
			}
		case "SET_NOTICE_COUNT":
			if(action.editNoticeCount){
				return {
					...state,
					noticeCount: state.noticeCount - action.editNoticeCount
				}
			}else{
				return {
					...state,
					noticeCount: action.noticeCount
				}
			}
		case "CLEAR_NOTICE" :
			return {
				...state,
				noticeList: [],
				offset: 0,
			};
		default:
			return state;
	}
}