const DetailPrice = ({ price, pricetype }) => {

	return(
		<div className="i_product-detail-price">
			<div className="i_product-detail-price-items">
				{price.map((item, index) => {
					index++;
					return (
						<div key={index} className={`i_product-detail-price-item ${price.length === 1 ? 'alone-item' : false}`}>
							<div className="i_product-detail-price-item-name">
								<span>{pricetype[item.type_id]?.name}</span>
							</div>
							<div className="i_product-detail-price-item-value">
								<span>{item.price} {item.currency}</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default DetailPrice;