
import kzFlag from "../../images/countries/kz.jpg";
import uzFlag from "../../images/countries/uz.jpg";
import kgFlag from "../../images/countries/kg.webp";
import tjFlag from "../../images/countries/tj.svg";
import ruFlag from "../../images/countries/ru.svg";
import uaeFlag from "../../images/countries/uae.svg";
import geFlag from "../../images/countries/ge.svg";
import byFlag from "../../images/countries/by.svg";
import trFlag from "../../images/countries/tr.svg";
import cnFlag from "../../images/countries/cn.svg";
import inFlag from "../../images/countries/in.svg";
import sgFlag from "../../images/countries/sg.svg";

import {useSelector} from "react-redux";

const CountryViewer = () => {
	/*Показываем домен первого уровня*/
	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	// const siteID = 'kg'

	const countries = {
		'uz' : { name: 'Узбекистан', svg: uzFlag },
		'kz' : { name: 'Казахстан', svg: kzFlag },
		'kg' : { name: 'Кыргызстан', svg: kgFlag },
		'tj' : { name: 'Таджикистан', svg: tjFlag },
		'ru' : { name: 'Россия', svg: ruFlag },
		'ae' : { name: 'United Arab Emirates', svg: uaeFlag },
		'ge' : { name: 'Georgia', svg: geFlag },
		'by' : { name: 'Belarus', svg: byFlag },
		'tr' : { name: 'Turkey', svg: trFlag },
		'cn' : { name: 'China', svg: cnFlag },
		'in' : { name: 'India', svg: inFlag },
		'sg' : { name: 'Singapore', svg: sgFlag }
	}

	return(
		<div className={`i_country-viewer ${siteID}`}>
			<div className="i_country-viewer-svg">
				{
					countries[siteID] && <img src={`${countries[siteID].svg}`} alt={countries[siteID].name}/>
				}
			</div>
			<div className="i_country-viewer-text">
				<span>
					{
						countries[siteID] && countries[siteID].name
					}
				</span>
			</div>
		</div>
	)
}

export default CountryViewer;