import {useState} from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import "../styles/mainPage.scss"
import {Link} from "react-router-dom";

//Личный кабинет/главная страница
const MainPage = () => {
	//Создаем состояния для компонентов и виджетов
	const [isLoading, setIsLoading] = useState(false)

	return (
		<div className={'i_main-page'}>
			{/*Если данные полностью загрузились , то показываем виджеты*/}
			{!isLoading && (
				<div className={'i_dealer-main-page'}>
					<span className={'i_text-bold'}>Вы находитесь в вашем личном кабинете дилера!</span>
					<span>Здесь, в разделе <Link to={'/distributors/'}>Дистрибьюторы</Link>, вы можете найти нужного вам Дистрибьютора и сделать запрос на подключение. Дистрибьютор получит ваш запрос и, после подтверждения, у вас появится доступ к его каталогу.</span>
					<span>Доступный каталог дистрибьютора вы можете увидеть в разделе Каталоги - <Link to={'/catalogs/distributor/'}>Каталоги дистрибьюторов</Link>. Кликнув по названию каталога, вы перейдете на страницу со списком категорий и товаров. Переходя по категориям, вы можете посмотреть товары, цены и остатки на складе выбранного каталога дистрибьютора.</span>
					<span>Раздел каталог еще содержит подраздел <Link to={'/catalogs/my/'}>Мои каталоги</Link>. В нем вы можете создавать свои каталоги с нужной вам структурой категорий. Это необходимо, чтобы в дальнейшем сопоставить каталог дистрибьютора с вашим каталогом и товары из категорий каталога дистрибьютора выгружались в категории вашего каталога.</span>
					<span>Привязанные товары и категории будут автоматически обновляться в соответствии с изменениями у дистрибьютора. Вы можете управлять ими как в своем каталоге, так и выгружать эти данные на сторонние платформы. Функционал по выгрузки данных находиться в стадии активной разработки и будет добавлен в ближайшее время</span>
					<span>Функциональность раздела постоянно дорабатывается и расширяется. Следить за обновлениями вы можете в нашем <a href="https://t.me/apicore_dealer/" target={'_blank'}>телеграмм канале для Дилеров</a> или в <a href="https://www.instagram.com/apicore.kz/" target={'_blank'}>Инстаграм</a></span>
				</div>
			)}
			{/*Если данные не загрузились, то показываем Skeleton*/}
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={3}
							height={'80vh'}
							width={'30%'}
							inline={true}
							borderRadius={30}
							containerClassName={'i_main-page-skeleton'}
						/>
					</div>
				</>
			)}


		</div>
	)
}

export default MainPage;


