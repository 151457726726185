import {useEffect, useRef, useState} from "react";
import {ReactSVG} from "react-svg";
import arrow from '../../../images/burger-language-arrow.svg'

export default function SelectDropdown({item, label, onChange, defaultValue}) {
	//Состояние и ссылка на компоненту
	const [active, setActive] = useState(false)
	const [currentSelect, setCurrentSelect] = useState('')
	const selectRef = useRef()

	const changeSelectTitle = (title, item) => {
		setCurrentSelect(title)
		setActive(false)
		onChange(item)
	}

	// useEffect для обработки клика за пределами компонента
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (selectRef.current && !selectRef.current.contains(event.target)) {
				setActive(false);
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);


	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			setActive(false);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<div className={`i_select-dropdown`} ref={selectRef}>
			<div className="i_select-dropdown-head" onClick={()=>{setActive(!active)}}>
				<span className={'i_select-dropdown-head-label'}>{label}</span>
				<span>{currentSelect ? currentSelect : defaultValue}</span>
				<div className={`i_select-dropdown-arrow ${active ? 'active' : ''}`}>
					<ReactSVG src={arrow} />
				</div>
			</div>
			<div className={`i_select-dropdown-body ${active ? 'active' : ''}`}>
				<div className="i_select-dropdown-body-items">
					{item?.map((item, index) => {
						let title = item.name

						return(
							<div className="i_select-dropdown-body-item" key={index} onClick={()=>{changeSelectTitle(title, item)}}>
								<span>{title}</span>
							</div>
						)
					})}

				</div>
			</div>
		</div>
	);
}
