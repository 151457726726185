import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";

const DeleteDistributorCatalog = () => {

	const dispatch = useDispatch();
	const currentCatalogItem = useSelector(state => state.multiPopup.currentCatalogItem);

	const deleteDistributor = async () => {
		try{
			const response = await api.cabinetApi.deleteSourceList({code: currentCatalogItem});
			if(response.status === true){
				const sourceCatalogList = await api.cabinetApi.getSourceList();
				dispatch({type: "REFRESH_SOURCE_CATALOG_LIST", catalogList: sourceCatalogList.sources})
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
			}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>С внешним источником будут удалены его категории и товары.<br/><br/>Вы действительно хотите удалить внешний источник?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={()=>{deleteDistributor()}}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteDistributorCatalog;