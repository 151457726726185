import {api} from "../../../../api";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import Skeleton from "react-loading-skeleton";
import "../styles/settingsPage.scss"
import {ReactSVG} from "react-svg";

import editImg from '../../../../images/catalogs/catalog-edit.svg'
import deleteImg from '../../../../images/catalogs/catalog-trash.svg'
import plus from '../../../../images/catalogs/plus.svg'
import eyeOpen from "../../../../images/eye.svg"
import eyeClose from "../../../../images/eye-close.svg"

import {useDispatch, useSelector} from "react-redux";

const SettingsPage = () => {
	const {t} = useTranslation();

	//Храним api ключ
	const [apiKey, setApiKey] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [showApiKey, setShowApiKey] = useState(false)

	const dispatch = useDispatch();

	const priceTypes = useSelector(state => state.settings.priceTypes)

	//Ассинхронная функция получения данных с сервера
	const getData = async () => {
		try{
			const apiKey = await api.cabinetApi.getApiKeys();
			const priceType = await api.cabinetApi.getPriceType();

			if(priceType.status === true) {
				dispatch({type: "SET_PRICE_TYPES", priceTypes: priceType.pricetypes})
			}
			if(apiKey.status === false){
				setApiKey(t('I_API_KEY_NOT_FOUND'))
				setShowApiKey(true)
			}else if(apiKey.status === true){
				setApiKey(apiKey.key)
			}

			setIsLoading(false)

		}catch (err){
			console.log(err)
		}
	}

	//Фунцкия генерации нового api ключа
	const generateApiKey = async () => {
		try{
			const result = await api.cabinetApi.generateApiKey();
			if(result.status === false){
				setApiKey(t('I_API_KEY_NOT_FOUND'))
			}else if(result.status === true){
				setApiKey(result.key)
				setShowApiKey(true)
			}
		}catch (err){
			console.log(err)
		}
	}

	//Функция переключения показа api ключа
	const switchShowApiKey = () => {
		setShowApiKey(!showApiKey)
	}

	//Функция открытия модального окна добавления типа цены
	const openAddPriceTypeModal = () => {
		dispatch({type: "OPEN_MODAL", modalType: 'addPriceTypeModal', modalLevel: 1})
	}

	//Функция открытия модального окна редактирования типа цены
	const openEditPriceTypeModal = (code, name) => {
		dispatch({type: "OPEN_MODAL", modalType: 'editPriceTypeModal', modalLevel: 1, modalData: {currentPriceTypeItem: code, currentPriceTypeName:name}})
	}

	//Открываем попап удаления типа цены
	const openPopupDelete = (code) =>{
		dispatch({type:'SWITCH_POPUP', popupType: 'deletePriceType', popupIsOpen: true, currentPriceTypeItem: code})
	}

	useEffect(()=>{
		getData()
	},[])

	return (
		<div className="i_settings-page">
			{isLoading && (
				<>
					<Skeleton
						count={1}
						height={'14rem'}
						width={'100%'}
						inline={true}
						borderRadius={20}
					/>
				</>
			)}
			{!isLoading && (
				<div className="i_settings-content">
					<div className="i_settings-prices">
						<div className="i_settings-prices-title">
							<span>Типы цен</span>
						</div>
						<div className="i_settings-prices-head">
							<div className="i_prices-head-items">
								<div className="i_prices-head-item code">
									<span>Код</span>
								</div>
								<div className="i_prices-head-item title">
									<span>Название</span>
								</div>
								<div className="i_prices-head-item lvl">
									<span>Доступно</span>
								</div>
							</div>
						</div>
						<div className="i_settings-prices-items">
							<div className="i_settings-prices-add" onClick={openAddPriceTypeModal}>
								<ReactSVG src={plus} />
							</div>
							{priceTypes && priceTypes.map((item, index) => (
								<div className="i_settings-prices-item" key={index}>
									<div className="i_settings-prices-info">
										<div className="i_settings-prices-info-item code">
											<span>{item.ext_id}</span>
										</div>
										<div className="i_settings-prices-info-item title">
											<span>{item.name}</span>
										</div>
										<div className="i_settings-prices-info-item lvl">
											<span>{item.access === null || item.access.length === 0 ? 'Доступно всем': 'Доступно некоторым дилерам'}</span>
										</div>
									</div>
									<div className="i_settings-prices-item-action">
										<div className="i_settings-prices-item-edit" onClick={()=>{openEditPriceTypeModal(item.ext_id, item.name)}}>
											<ReactSVG src={editImg} />
										</div>
										<div className="i_settings-prices-item-delete" onClick={()=>{openPopupDelete(item.ext_id)}}>
											<ReactSVG src={deleteImg} />
										</div>
									</div>
								</div>
							)
							)}
						</div>
					</div>
					<div className="i_settings-items">
						<div className="i_settings-item">
							<div className="i_settings-item-title">
								<span>{t('I_API_KEY')}</span>
							</div>
							<div className="i_settings-item-value">
								<span>{showApiKey ? apiKey : '##############'}</span>
								<ReactSVG src={showApiKey ? eyeOpen : eyeClose} className={'i_settings-item-showkey'} onClick={switchShowApiKey}/>
								<CopyToClipboardButton textToCopy={apiKey}/>
							</div>
							<div className="i_settings-item-button" onClick={generateApiKey}>
								<span>{t("I_API_KEY_BTN")}</span>
							</div>

							<div className="i_setting-item-alert">
								<span>! {t('I_API_KEY_ALERT')}</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SettingsPage;