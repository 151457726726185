import {useSelector} from "react-redux";
import AttachSourceCategory from "../multitools/components/AttachSourceCategory";
import AttachDistributorCategory from "../multitools/components/AttachDistributorCategory";


const AttachCategoryModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)

	return (
		<>
			{currentTools === "catalogs-source-inner" && <AttachSourceCategory />}
			{currentTools === "distributor-catalogs-inner" && <AttachDistributorCategory />}
		</>
	)
}

export default AttachCategoryModal