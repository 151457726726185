const initialState = {
	data : null,
	title: null,
	menu : null,
}

export const appDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case "CHANGE_TITLE" :
			return {
				...state,
				title: action.title
			}
		case "SET_MENU" :
			return {
				...state,
				menu : action.menu
			}
		default:
			return state;
	}
}