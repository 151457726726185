const initialState = {
	show: false,
	content: null,
}

export const cursorHelpReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SWITCH_CURSOR_HELP":
			return {
				...state,
				show: action.show,
				content: action.content
			};
		default:
			return state;
	}
}