import {ReactComponent as OpenSvg} from "../../../../images/open-list.svg"
import {useDispatch, useSelector} from "react-redux";
import styles from './OpenCatalog.module.scss'

const OpenCatalogs = ({store, updateCategoryAction}) => {

	const dispatch = useDispatch();


	// Вызываем useSelector один раз, а затем определяем нужные данные
	const storeCategoryList = store?.categoryList;

	const defaultCategoryList = useSelector(state => state.catalog.categoryList.categoryList);

	// Устанавливаем categoryList в зависимости от isModal
	const categoryList = store ? storeCategoryList : defaultCategoryList;

	// Рекурсивно обновляет список категорий
	function updateActiveList(list){
		return list.map((item) => {
			if(item.has_children){
				item.is_show = true;
			}
			return item
		})
	}

	// Функция openCatalogs открывает каталоги
	function openCatalogs() {
		let updatedList = updateActiveList(categoryList)
		updateCategoryAction ? dispatch({type: updateCategoryAction, categoryList: updatedList}) : dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: updatedList})
	}

	return (
		<div className={`${styles.toolsItem} ${categoryList?.length === 0 ? styles.disabled : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Развернуть категории'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={openCatalogs}
		>
			<OpenSvg />
		</div>
	)
}

export default OpenCatalogs;