import {useEffect, useState} from "react";
import {api} from "../../../../api";

const BillingPage = () => {

	//Храним состояние блока тариф
	const [tariff, setTariff] = useState(null)
	const [loading, setLoading] = useState(true)

	const getData = async () => {
		setLoading(true)
		try{
			const currentTariff = await api.cabinetApi.getCurrentTariff();

			if(currentTariff.status === true){
				let tarrifObj =
					[
						{
							name: 'Подключено дистрибьюторов',
							value: currentTariff.tariff.distributor_connected
						},
					]

				setTariff(tarrifObj)
			}
		}catch (err){
			console.log(err)
		}finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getData();
	}, [])


	return(
		<div className={`i_billing`}>
			{loading && (
				<div className="i_loading">
					<div className="i_loading-icon"></div>
				</div>
			)}
			{!loading && (
				<div className="i_billing-tariff">
					<div className="i_billing-tariff-title">
						<span>Текущий тариф</span>
					</div>
					<div className="i_billing-tariff-items">
						{tariff && tariff.map((item, index) => (
							<div className="i_billing-tariff-item" key={index}>
								<div className="i_billing-tariff-item-name">
									<span>{item.name}:</span>
								</div>
								<div className="i_billing-tariff-item-value">
									<span>{item.value}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default BillingPage;