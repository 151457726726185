import { API_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class AuthApi{
	//API проверяет наличие пользователя
	//Передаём login
	checkLogin(login){
		return multiFetch(`${API_URL}/auth/check.login`, {login:login}, {});
	}

	//API авторизириует пользователя
	//Передаём body с login и password
	loginUser(body){
		return multiFetch(`${API_URL}/auth/login`, body, {});
	}

	//API деавторизирует пользователя
	//Передаём пустой body
	logoutUser(){
		return multiFetch(`${API_URL}/auth/logout`, {}, {});
	}

	//API регистрирует пользователя
	//Передаём body {login: "" , password: "" , recaptcha: "" , language: ""}
	registerUser(body){
		return multiFetch(`${API_URL}/auth/register`, body, {});
	}

	//API восстанавливает пароль пользователя
	//Передаём body {email: ""}
	sendRestorePassword(body){
		return multiFetch(`${API_URL}/auth/restore.send`, body, {});
	}
}

