import styles from './Button.module.scss'

const Button = ({title, onClick}) => {
	return (
		<button className={styles.button} onClick={onClick}>
			<span>{title}</span>
		</button>
	)
}

export default Button;