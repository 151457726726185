import {ReactSVG} from "react-svg";
import deleteSvg from '../../../images/catalogs/category/delete.svg'
import {useDispatch, useSelector} from "react-redux";

const DeleteElement = () => {
	const dispatch = useDispatch();
	const currentTools = useSelector(state => state.multiTools.currentTools)

	// Вызываем оба useSelector вне условия
	const checkedProductId = useSelector(state => state.markups.list.checkedProductId);
	const currentCategoryId = useSelector(state => state.catalog.list.products.checkedProductId);

	// Определяем currentId уже после вызова хуков
	const currentId = currentTools === 'markups-my-catalog' ? checkedProductId : currentCategoryId;

	return(
		<div className={`i_element-tools-item ${!currentId || currentId.length === 0 ? 'disabled' : ''}`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Удалить элемент'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type:'SWITCH_POPUP', popupType: 'deleteElement', popupIsOpen: true})}
		>
			<ReactSVG src={`${deleteSvg}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default DeleteElement;