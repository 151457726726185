const initialState = {
	showTabBar: null,
	showTabBarCatalog: null
}

export const menuReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SHOW_TABBAR_MORE":
			return {
				...state,
				showTabBar: action.showTabBar
			}
		case "SHOW_TABBAR_CATALOG":
			return {
				...state,
				showTabBarCatalog: action.showTabBarCatalog
			}
		default:
			return state;
	}
}