const initialState = {
	notificationIsOpen : false,
	notificationContent : null,
	notificationType: null,
}

export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SHOW_NOTIFICATION" :
			return {
				...state,
				notificationIsOpen: action.notificationIsOpen,
				notificationContent: action.notificationContent,
				notificationType: action.notificationType
			}
		default:
			return state;
	}
}