import {ReactSVG} from "react-svg";
import edit from '../../../images/catalogs/category/edit.svg'
import {useDispatch} from "react-redux";

const EditElement = ({item}) => {

	const dispatch = useDispatch();

	const openModal = () => {
		dispatch({type: "OPEN_MODAL", modalType: 'editElementModal', modalLevel: 1, modalData: {item: item}})
	}

	return(
		<div className={`i_element-tools-item`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Изменить элемент'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={openModal}
		>
			<ReactSVG src={`${edit}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default EditElement;