import {useNavigate} from "react-router-dom";
import Loader from "../../components/Loader";
import {useEffect} from "react";

const Redirect = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('/');
	}, []);

	return(
		<Loader />
	)
}

export default Redirect;