import circleLogo from '../../images/logo/logo_circle.svg'
import ripples from '../../images/tube-spinner.svg'

const Loader = ({show}) =>{
	return(
		<div className={`i_loader ${show ? '' : 'hide'}`} >
			<img src={ripples} alt="ripples" className={'i_loader-ripples'}/>
			<img src={circleLogo} alt="logo" className={'i_loader-logo'}/>
		</div>
	)
}

export default Loader;