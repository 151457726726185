import wbLogo from '../../../../images/logo/wildberries.png'
import '../styles/marketplacesPage.scss'
import {api} from "../../../../api";
import {useEffect, useState} from "react";

const MarketplacesPage = () => {

	const [marketplaces, setMarketplaces] = useState(null)

	const getData = async () => {
		try {
			const result = await api.cabinetApi.getMarketplaceList();

			setMarketplaces(result.marketplaces)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<div className={'i_marketplaces'}>
			<div className="i_marketplaces-items">
				{marketplaces && marketplaces.map((item, index) => {
					return (
						<div className="i_marketplaces-item" key={index}>
							<div className="i_marketplaces-item-name">
								<span>{item.name}</span>
							</div>
							<div className={`i_marketplaces-item-status ${item.status}`}>
								<span>{item.status === 'soon' ? 'Скоро' : 'В плане'}</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	);
}

export default MarketplacesPage;