const initialState = {
	uploadedImages: null,
}

export const uploadImageReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_UPLOADED_IMAGES":
			return {
				...state,
				uploadedImages: action.uploadedImages
			}
		case "CLEAR_UPLOADED_IMAGES":
			return {
				...state,
				uploadedImages: null
			}
		default:
			return state;
	}
}