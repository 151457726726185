import { useEffect, useRef, useState } from "react";
import styles from './NoticeList.module.scss';
import { api } from "../../../api";
import { useDispatch } from "react-redux";

const NoticeList = ({ store }) => {
	const [viewedItems, setViewedItems] = useState([]);
	const itemRefs = useRef([]); // Храним ссылки на каждый элемент уведомления
	const dispatch = useDispatch();
	const items = store.noticeList;
	const [viewedItemsUpdated, setViewedItemsUpdated] = useState(false);
	const [viewedToSend, setViewedToSend] = useState([]);
	const timerRef = useRef(null); // Храним таймер для сброса

	useEffect(() => {
		items.forEach((item) => {
			if (item.read && !viewedItems.includes(item.id) && items.length < store.offset) {
				setViewedItems((prevViewedItems) => [...prevViewedItems, item.id]);
			}
		});
	}, [items]);

	const getData = async () => {
		const response = await api.cabinetApi.getNotificationList({ offset: store.offset });
		dispatch({ type: "ADD_NOTICE_LIST", noticeList: response.notifications });
		dispatch({ type: "SET_NOTICE_OFFSET", offset: response.next_offset });
	};

	const sendNoticeRead = async (ids) => {
		const response = await api.cabinetApi.setNotificationRead({id: ids});

		dispatch({ type: "SET_NOTICE_COUNT", editNoticeCount: response.count});
	}


	useEffect( () => {
		if(viewedItems.length === store.offset){
			getData();
		}
	}, [viewedItems]);

	// Логика для проверки изменений в viewedItems и запуска таймера
	useEffect(() => {
		if (viewedItemsUpdated) {
			// Сброс предыдущего таймера
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}

			// Устанавливаем новый таймер на 2 секунды
			timerRef.current = setTimeout(() => {

				let updatedViewedToSend = viewedToSend.map((id) => {
					const matchedItem = items.find((item) => item.id === id && !item.read); // Ищем элемент
					if (matchedItem) {
						return id; // Возвращаем id, если условие выполняется
					}
					return null; // Возвращаем null, если условие не выполнено
				});

				updatedViewedToSend = updatedViewedToSend.filter(id => id !== null);

				if(updatedViewedToSend.length > 0){
					sendNoticeRead(updatedViewedToSend);
				}

				setViewedToSend([]); // Очищаем список для отправки
				setViewedItemsUpdated(false); // Сбрасываем флаг после отправки запроса
			}, 2000); // Время задержки в миллисекундах
		}
	}, [viewedItemsUpdated, viewedItems]);

	useEffect(() => {
		// Callback-функция для Intersection Observer
		const observerCallback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const itemId = parseInt(entry.target.getAttribute("data-id"));
					if (!viewedItems.includes(itemId)) {
						// Используем функцию обновления с предыдущим состоянием
						setViewedItems((prevViewedItems) => {
							// Проверяем еще раз на дублирование
							if (!prevViewedItems.includes(itemId)) {
								setViewedItemsUpdated(true); // Устанавливаем флаг обновления
								setViewedToSend((prevViewedToSend) => [...prevViewedToSend, itemId]);
								return [...prevViewedItems, itemId];
							}
							return prevViewedItems;
						});
					}
				}
			});
		};

		const observer = new IntersectionObserver(observerCallback, {
			threshold: 1, // Элемент должен быть полностью видимым, чтобы считаться прочитанным
		});

		// Наблюдаем за каждым элементом уведомления
		itemRefs.current.forEach((ref) => {
			if (ref) {
				observer.observe(ref);
			}
		});

		// Очищаем наблюдателей при размонтировании компонента
		return () => {
			itemRefs.current.forEach((ref) => {
				if (ref) {
					observer.unobserve(ref);
				}
			});
		};
	}, [items, viewedItems]);

	return (
		<>
			{items.map((item, index) => (
				<div
					key={item.id}
					data-id={item.id}
					ref={(el) => (itemRefs.current[index] = el)} // Присваиваем ссылку на элемент
					className={`${styles.modalItem} ${!viewedItems.includes(item.id) && !item.read ? styles.modalItemUnread : ''}`}
				>
					<div className={styles.modalItemDate}>
						<span>{item.time}</span>
					</div>
					<div className={styles.modalItemMessage}>
						<span dangerouslySetInnerHTML={{ __html: item.message }}></span>
					</div>
				</div>
			))}
		</>
	);
};

export default NoticeList;
