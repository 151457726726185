import {useEffect, useState} from "react";
import {api} from "../api";
import {useDispatch, useSelector} from "react-redux";
import CatalogTools from "../pages/components/CatalogTools";
import OpenCatalogs from "../pages/main/components/OpenCatalogs/OpenCatalogs";
import CloseCatalogs from "../pages/main/components/CloseCatalogs/CloseCatalogs";
import SelectProductList from "./components/SelectProductList";
import Skeleton from "react-loading-skeleton";
import ButtonLoader from "../pages/main/components/ButtonLoader";
import {ScrollBox, SelectCategoryList} from "../utils/ui";

const SelectCategoryAndProductModal = () => {
	const [load, setLoad] = useState(true);
	const dispatch = useDispatch();
	const store = useSelector(state => state.markups);
	const code = store.catalogCode;

	const closeModal = () => {
		dispatch({type: 'CLOSE_MODAL', modalLevel: 2})
	}

	const getData = async () => {
		try {
			const categoryList = await api.cabinetApi.getCatalogCategoryList({code: code});
			const productList = await api.cabinetApi.getCatalogProductList({code: code, offset: 0});

			dispatch({type:"SET_MARKUPS_CATEGORY", categoryList: categoryList.categories})
			dispatch({type:"SET_MARKUPS_PRODUCT", productList: productList.products})
			dispatch({type:"SET_NEXT_OFFSET", nextOffset: productList.next_offset})

			setLoad(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getData()
	}, []);
	return (
		<div className={'i_select-modal'}>
			<div className="i_select-modal-title">
				<span>Выбор категорий товаров</span>
			</div>

			<div className="i_select-modal-content">
				<ScrollBox height={'95vh'} width={'100%'} >
					{
						load ? (
							<div style={{display: 'flex', height: "100%"}}>
								<Skeleton
									count={2}
									height={'100%'}
									inline={true}
								/>
							</div>
						) : (
							<div className="i_select-modal-items">
								<div className="i_select-modal-item category">
									<div className="i_catalog-tools">
										<CatalogTools/>
										<div className="i_catalog-tools-right">
											<OpenCatalogs store={store} updateCategoryAction={'SET_MARKUPS_CATEGORY'}/>
											<CloseCatalogs store={store} updateCategoryAction={'SET_MARKUPS_CATEGORY'}/>
										</div>
									</div>
									<SelectCategoryList store={store}/>
								</div>
								<div className="i_select-modal-item">
									<SelectProductList store={store}/>
								</div>
							</div>
						)
					}
				</ScrollBox>
				<ButtonLoader title={'Сохранить'} onClick={closeModal}/>
			</div>
		</div>
	)
}

export default SelectCategoryAndProductModal;