import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";

const DeleteMyCategory = () => {
	const dispatch = useDispatch();
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId);
	const catalogCategoryList = useSelector(state => state.catalog.categoryList.categoryList);

	const deleteCategory = async () => {
		try{
			const response = await api.cabinetApi.deleteCatalogCategory({id: currentCategoryId});
			if(response.status === true){
				catalogCategoryList.forEach(el => {
					if(response.parent && el.id === response.parent.id){
						el.has_children = response.parent.has_children
					}
				})
				const updatedList = catalogCategoryList.filter(el => el.id !== currentCategoryId);

				dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: updatedList})
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
			}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>С категорией будут удалены все вложенные подкатегории. <br/><br/>Вы действительно хотите удалить категорию?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={deleteCategory}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteMyCategory;