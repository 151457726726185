import { AccountApi } from "./AccountApi";
import { AuthApi } from "./AuthApi";
import { CabinetApi } from "./CabinetApi";

export class Api {
	constructor() {
		this.accountApi = new AccountApi()
		this.authApi = new AuthApi()
		this.cabinetApi = new CabinetApi()
	}
}

export const api = new Api()