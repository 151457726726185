const initialState = {
	data : null
}

export const userDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_USER_DATA" :
			return {
				...state,
				data: action.userData
			}
		default:
			return state;
	}
}