import infoImg from "../../../images/info.svg";
import styles from './EmptyBlock.module.scss';

const EmptyBlock = ({ title, fullWidth }) => {
	return(
		<>
			<div className={`${styles.emptyBlock} ${fullWidth ? styles.emptyBlockFull : ''}`}>
				<div className={styles.emptyBlockTitle}>
					<img src={`${infoImg}`} alt="i"/>
					<span>{title}</span>
				</div>
			</div>
		</>
	)
}

export default EmptyBlock;