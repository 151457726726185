import Skeleton from "react-loading-skeleton";
import {api} from "../../../../../api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "../../styles/markupsPage.scss"
import {MarkupsList} from "../../../components/ProductList/";
import ElementTools from "../../../../components/ElementTools";

const MarkupsPage = () => {

	// Получаем диспатч
	const dispatch = useDispatch();

	// Состояния для загрузки и списка категорий
	const [isLoading, setIsLoading] = useState(true)

	// Получаем код каталога из URL
	let {code} = useParams();

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const markupList = await api.cabinetApi.getCatalogMarkupList({catalog_code: code});

			dispatch({type: "SET_MARKUPS_LIST_COLUMNS", columns: markupList.columns})
			dispatch({type: "SET_MARKUPS_LIST_PRODUCTS", products: markupList.markups})

			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		// Устанавливаем текущий мультитул
		setTimeout(()=>{
			dispatch({type: "SET_TOOLS", currentTools: "markups-my-catalog"})
		},5)

		dispatch({type: "SET_MARKUPS_CATALOG_CODE", catalogCode: code})
		// Получаем список категорий и товаров
		getList()
	}, [])

	const store = useSelector(state => state.markups)


	return (
		<div className={`i_catalog-page markups`}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height: "100%"}}>
						<Skeleton
							count={2}
							height={'100%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-in-skeleton'}
						/>
					</div>
				</>
			)}
			<div className={`i_catalog-main`}>
				{/*Выводим список товаров*/}
					<ElementTools />
				<div className="i_catalog-product">
					<MarkupsList store={store}/>
				</div>
			</div>
		</div>
	)
}

export default MarkupsPage;