import { useState, useEffect } from 'react';
import { ReactSVG } from "react-svg";
import arrowUp from '../../images/catalogs/category/arrow-up.svg';
import arrowDown from '../../images/catalogs/category/arrow-down.svg';

const SearchHighlight = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [matchCount, setMatchCount] = useState(0);

	useEffect(() => {
		highlightText();
	}, [searchTerm]);

	const highlightText = () => {
		if (!searchTerm.trim()) {
			removeHighlight();
			setMatchCount(0);
			setCurrentIndex(-1);
			return;
		}

		const regex = new RegExp(`(${searchTerm})`, 'gi');
		const titles = document.querySelectorAll('.i_add-category-list-content .i_catalog-category-item-title span');

		titles.forEach((title) => {
			const text = title.textContent;
			const highlightedText = text.replace(regex, match => `<mark>${match}</mark>`);
			title.innerHTML = highlightedText;
		});

		const matches = document.querySelectorAll('mark');
		setMatchCount(matches.length);
		setCurrentIndex(matches.length > 0 ? 0 : -1);
	};

	const handlePrev = () => {
		if (matchCount === 0) return;

		const newIndex = currentIndex <= 0 ? matchCount - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
		scrollToCurrent(newIndex);
	};

	const handleNext = () => {
		if (matchCount === 0) return;

		const newIndex = currentIndex >= matchCount - 1 ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
		scrollToCurrent(newIndex);
	};

	const scrollToCurrent = (index) => {
		const titles = document.querySelectorAll('mark');
		if (titles[index]) {
			titles[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
			removeHighlight();
			titles[index].classList.add('current');
		}
	};

	const removeHighlight = () => {
		const highlightedTitles = document.querySelectorAll('mark');
		highlightedTitles.forEach(title => {
			title.classList.remove('current');
		});
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleNext();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [matchCount, currentIndex]);

	return (
		<div className={'i_search-highlight'}>
			<input
				type="text"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				placeholder="Поиск..."
			/>
			<div className="i_search-highlight-btn">
				<button onClick={handlePrev} disabled={matchCount <= 1}><ReactSVG src={arrowUp} /></button>
				<button onClick={handleNext} disabled={matchCount <= 1}><ReactSVG src={arrowDown} /></button>
			</div>
		</div>
	);
};

export default SearchHighlight;
