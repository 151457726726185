import {useEffect, useState, useRef} from "react";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../../pages/main/components/ButtonLoader";
import styles from './AddEmailModal.module.scss';
import CustomInput from "../../pages/main/components/CustomInput";
import {ReactComponent as CloseSvg} from "../../images/closeModal.svg"

import {Button, ScrollBox} from "../../utils/ui";
import {api} from "../../api";
import {useDispatch} from "react-redux";

const AddEmailModal = () => {
	// Состояния для инпутов
	const [emails, setEmails] = useState(['']); // Начинаем с одного пустого email

	// Состояния компоненты
	const [load, setLoad] = useState(false);
	const [error, setError] = useState([]);
	const inputRefs = useRef([]);
	const dispatch = useDispatch();

	// Для перевода
	const { t } = useTranslation();

	// Функция для изменения конкретного email по индексу
	const handleEmailChange = (index, value) => {
		const updatedEmails = [...emails]; // Копируем массив
		updatedEmails[index] = value; // Изменяем конкретное значение по индексу
		setEmails(updatedEmails); // Обновляем состояние
	};

	// Добавляем новый email (пустой) в массив
	const handleAddEmail = () => {
		setEmails([...emails, '']); // Добавляем новый пустой email в массив
		setError([''])
	};

	const handleDeleteEmail = (index) => {
		const input = inputRefs.current[index];
		input?.classList.add(styles.isDeleted);

		setTimeout(() => {
			const updatedEmails = emails.filter((_, i) => i !== index);
			setEmails(updatedEmails);
			inputRefs.current.splice(index, 1); // Удаляем реф из массива
			input?.classList.remove(styles.isDeleted);
		},200)
	};

	// Отправка формы
	const formSubmit = async () => {
		setLoad(true);
		setError([]); // Очищаем ошибки

		try {
			const response = await api.cabinetApi.addInvitation({email : emails });

			if(response.status === true){
				if(response.error.length === 0){
					dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
					dispatch({type: "CLOSE_MODAL", modalLevel:1})
				}else{
					dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
				}
			}else{
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'failed'})
			}

			if(response.error){
				const errors = emails.map((item, index) => {
					if(response.error[item]){
						return response.error[item]
					}else{
						handleDeleteEmail(index)
					}
				})

				const cleanedError = errors.filter((item, i) => item !== undefined);

				setError(cleanedError)
			}
		} catch (err) {

		} finally {
			setLoad(false);
		}
	};

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Приглашение дилера</span>
			</div>
			<ScrollBox>
				<div className={styles.modalItems}>
					{emails.map((email, index) => (
						<div className={styles.modalItem} key={index} ref={el => inputRefs.current[index] = el}>
							<CustomInput
								type={'text'}
								title={`Email ${index + 1}`}
								value={email} // Здесь мы привязываем значение email
								onChange={(e) => handleEmailChange(index, e)} // Обновляем конкретный email
								error={error[index]}
							/>
							<div className={styles.modalItemDelete} onClick={() => {
								handleDeleteEmail(index)
							}}>
								<CloseSvg/>
							</div>
						</div>
					))}
				</div>

				<div className={styles.modalBtn}>
					<Button title={'еще'} onClick={handleAddEmail} />
				</div>

				<div className={styles.modalBtn} onClick={formSubmit}>
					<ButtonLoader load={load} title={'Пригласить'} />
				</div>
			</ScrollBox>
		</div>
	);
};

export default AddEmailModal;
