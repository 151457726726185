const DetailPrice = ({ price }) => {
	console.log(price)
	return(
		<div className="i_product-detail-price">
			<div className="i_product-detail-price-items">
				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Закупочная цена</span>
					</div>
					<div className="i_product-detail-price-item-value">
			            <span>
			              {price && price.purchase ? (
				              <>
					              {price.purchase.print_price} {price.purchase.currency}
				              </>
			              ) : (
				              "Нет цены"
			              )}
			            </span>
					</div>
				</div>

				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Розничная цена</span>
					</div>
					<div className="i_product-detail-price-item-value">
			            <span>
			              {price && price.retail ? (
				              <>
					              {price.retail.print_price} {price.retail.currency}
				              </>
			              ) : (
				              "Нет цены"
			              )}
			            </span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DetailPrice;