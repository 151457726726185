const initialState = {
	priceTypes : null
}

export const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_PRICE_TYPES" :
			return {
				...state,
				priceTypes: action.priceTypes
			}
		default:
			return state;
	}
}