import closeImg from '../../images/closeModal.svg'
import {ReactSVG} from "react-svg";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {useEffect} from "react";

const Notification = () => {
	const dispatch = useDispatch();
	const notification = useSelector(state => state.notification)
	const closeNotification = () =>{
		dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: false, notificationContent: notification.notificationContent, notificationType: null})
	}

	useEffect(()=>{
		if(notification.notificationIsOpen){
			setTimeout(() => {
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: false, notificationContent: notification.notificationContent, notificationType: null})
			}, 4000)
		}
	},[notification.notificationIsOpen])

	return(
		<>
			<div className={`i_notification ${notification.notificationIsOpen ? 'show' : 'hide'} ${notification.notificationType === 'success' ? 'success' : 'failed'}`}>
				<div className="i_notification-content">
					<div className="i_notification-content-text">
						<span>{notification.notificationContent}</span>
					</div>
					<ReactSVG src={closeImg} className={'i_notification-close'} onClick={closeNotification}/>
				</div>
			</div>
		</>
	)
}

export default Notification;