import {useSelector} from "react-redux";
import CategoryList from "../../pages/main/components/CategoryList/CategoryList";
import {useEffect, useState} from "react";

const CategoryListMobileModal = () => {
	const [type, setType] = useState(null);

	const currentTools = useSelector(state => state.multiTools.currentTools);

	useEffect(() => {
		switch (currentTools) {
			case 'catalogs-source-inner':
				setType('source');
				break;
			case 'distributor-catalogs-inner':
				setType('distributor');
				break;
			default:
				setType(null);
		}
	}, []);

	const categoryList = useSelector(state => state.catalog.categoryList.categoryList);

	return (
		<CategoryList category={categoryList} mobile={true} type={type}/>
	)
}

export default CategoryListMobileModal;
