import {useSelector} from "react-redux";
import {TOOLS_CONFIG} from "../../utils/config";

const CatalogTools = () => {
	// Получаем диспатч и текущий URL


	// Получаем текущий мультитул из стейта
	const currentTools = useSelector(state => state.multiTools.currentTools)


	// Если текущий мультитул есть, то рендерим его
	return (
		<div className={`i_catalogtools ${!currentTools ? 'hide' : ''}`}>
			{currentTools && (
				TOOLS_CONFIG.pages[currentTools].catalogTools.components.map((Component, index) => {
					return <Component key={index}/>
				})
			)}
		</div>
	)
}

export default CatalogTools;