import {ReactSVG} from "react-svg";
import plus from '../../../images/catalogs/category/add-category.svg'
import {useDispatch} from "react-redux";

const AddElement = () => {

	const dispatch = useDispatch();

	return(
		<div className={`i_element-tools-item`}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Добавить элемент'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type: "OPEN_MODAL", modalType: 'addElementModal', modalLevel: 1})}
		>
			<ReactSVG src={`${plus}`} className={'i_element-tools-item-svg'}/>
		</div>
	)
}

export default AddElement;