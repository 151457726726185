import Main from "../main";
import SelectRole from "./pages/SelectRole";
import {useSelector} from "react-redux";

const Home = () =>{

	const userData = useSelector(state => state.userData.data)

	return(
		<div className="i_home">
			{userData.entity && (
				<>
					<Main />
				</>
			)}
			{!userData.entity && (
				<>
					<SelectRole />
				</>
			)}
		</div>
	)
}

export default Home;