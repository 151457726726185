import './style.scss'
import { Routes, Route } from "react-router-dom";
import {useSelector} from "react-redux";

import Layout from "./components/layout";

//Импорты для дистрибьютора
import DistributorMainPage from "./pages/distributor/mainPage";
import DistributorSettingsPage from "./pages/distributor/settingsPage";
import DealerPage from "./pages/distributor/dealerPage";
import DistributorCatalogPage from "./pages/distributor/catalogPage";
import DistributorCurrentCatalogPage from "./pages/distributor/catalog/[catalogId]";
import DistributorProfile from "./pages/distributor/profilePage";
import DistributorBilling from "./pages/distributor/billingPage";

//Импорты для дилера
import DealerMainPage from "./pages/dealer/mainPage";
import DealerSettingsPage from "./pages/dealer/settingsPage";
import DealerCatalogPage from "./pages/dealer/catalogPage";
import DealerCurrentCatalogPage from "./pages/dealer/[catalogId]";
import DistributorPage from "./pages/dealer/distributorPage";
import DealerCatalogDistributorPage from "./pages/dealer/catalogDistributorPage";
import DealerMatchingPage from "./pages/dealer/matchingPage";
import DealerDistributorCurrentCatalogPage from "./pages/dealer/[distributor_id][catalogId]";
import DealerProfile from "./pages/dealer/profilePage";
import DealerBilling from "./pages/dealer/billingPage";
import OrdersPage from "./pages/dealer/ordersPage";
import MarketplacesPage from "./pages/dealer/marketplacesPage";
import CatalogSources from "./pages/dealer/catalogSources";
import SourceCatalogPage from "./pages/dealer/sourceCatalog/[sourceId]";
import Redirect from "./components/Redirect";
import MarkupsPage from "./pages/dealer/markups/[catalogId]";
import useViewportHeight from "../../utils/useViewportHeight";
import useWindowWidth from "../../utils/useWindowWidth";



//Наша Main компонента
const Main = () =>{
	const role = useSelector(state => state.userData.data.entity.type)
	const viewportHeight = useViewportHeight();
	const windowWidth = useWindowWidth();

	return(
		<div className="i_main" style={windowWidth < 1024 ? {height: viewportHeight - 76} : {height: viewportHeight}}>
			<Routes>
				{/*Первый route отвечает за показ главной страницы с динамической подгрузкой всех компонентов*/}
				<Route path="/" element={<Layout />}>
					{role === 'distributor' && (
						<>
							<Route index element={<DistributorMainPage />} />
							<Route path="/catalogs" element={<DistributorCatalogPage />} />
							<Route path="/catalogs/:code/" element={<DistributorCurrentCatalogPage />} />
							<Route path="/dealers" element={<DealerPage />} />
							<Route path="/settings" element={<DistributorSettingsPage />} />
							<Route path="/profile" element={<DistributorProfile />} />
							<Route path="/billing" element={<DistributorBilling />} />
							<Route path="*" element={<Redirect />} />
						</>
					)}
					{role === 'dealer' && (
						<>
							<Route index element={<DealerMainPage />} />
							<Route path="/catalogs/my" element={<DealerCatalogPage />} />
							<Route path="/catalogs/distributor" element={<DealerCatalogDistributorPage />} />
							<Route path="/catalogs/my/:code" element={<DealerCurrentCatalogPage />} />
							<Route path="/catalogs/my/:code/markups/" element={ <MarkupsPage />} />
							<Route path="/catalogs/distributor/:distributor_id/:code" element={<DealerDistributorCurrentCatalogPage />} />
							<Route path="/distributors" element={<DistributorPage />} />
							<Route path="/settings" element={<DealerSettingsPage />} />
							<Route path="/matching" element={<DealerMatchingPage />} />
							<Route path="/profile" element={<DealerProfile />} />
							<Route path="/billing" element={<DealerBilling />} />
							<Route path="/orders" element={<OrdersPage />} />
							<Route path="/marketplaces" element={<MarketplacesPage />} />
							<Route path="/catalogs/sources" element={<CatalogSources />} />
							<Route path="/catalogs/sources/:code" element={<SourceCatalogPage />} />
							<Route path="*" element={<Redirect />} />
						</>
					)}
				</Route>
			</Routes>
		</div>
	)
}

export default Main;