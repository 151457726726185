const initialState = {
	modals: [
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: null,
			modalLevel: 1,
			modalHeight: null,
		},
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: null,
			modalLevel: 2,
			modalHeight: null
		},
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: null,
			modalLevel: 3,
			modalHeight: null
		},
	],
}

export const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case "OPEN_MODAL":
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalType: action.modalType,
							modalIsOpen: true,
							modalData: action.modalData,
							modalWidth: action.modalWidth,
							modalHeight: action.modalHeight
						}
					}
					return modal;
				})
			};
		case "SET_MODAL_DATA": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalData: action.modalData
						}
					}
					return modal;
				})
			}
		}
		case "SET_MODAL_CURRENT_CATEGORY_ID": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							currentCategoryId: action.currentCategoryId
						}
					}
					return modal;
				})
			}
		}
		case "CLEAR_MODAL" : {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalType: null,
							modalIsOpen: false,
							modalData: null,
							modalWidth: null,
							currentCategoryId: null,
							modalHeight: null
						}
					}
					return modal;
				})
			}
		}
		case "CLOSE_MODAL": {
			return{
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalType: null,
							modalIsOpen: false,
							modalData: null,
							modalWidth: null,
							currentCategoryId: null,
							modalLevel: action.modalLevel,
							modalHeight: null,
						}
					}
					return modal;
				})
			}
		}
		default:
			return state;
	}
}