import {useState} from "react";

const OrdersItems = () => {

	const [itemShow, setItemShow] = useState(false)

	return (
		<div className={'i_orders-items'}>
			<div className={`i_orders-item hidden`}>
				<div className="i_orders-item-header">
					<div className="i_orders-item-id">
						<span>381</span>
					</div>
					<div className="i_orders-item-client">
						<span>Владимир Масленков</span>
					</div>
					<div className="i_orders-item-date">
						<span>19.04.2024 18:30</span>
					</div>
					<div className="i_orders-item-status">
						<span>В обработке</span>
					</div>
					<div className="i_orders-item-distributors-count">
						<span>3 дистрибьютора</span>
					</div>
					<div className="i_orders-item-products-count">
						<span>10 товаров</span>
					</div>
					<div className="i_orders-item-summary">
						<span>1 320 090 KZT</span>
					</div>
				</div>
				<div className="i_orders-item-body">
					<div className="i_order-item-detail">
						<div className="i_order-item-client">
							<span>Клиент: </span>
							<span className={'i_order-item-detail-value'}>Дусупов Руслан</span>
						</div>
						<div className="i_order-item-email">
							<span>E-mail: </span>
							<span className={'i_order-item-detail-value'}>ruslan@mail.ru</span>
						</div>
						<div className="i_order-item-phone">
							<span>Телефон: </span>
							<span className={'i_order-item-detail-value'}>+7(777)324-93-08</span>
						</div>
						<div className="i_order-item-comments">
							<span>Комментарий: </span>
							<span className={'i_order-item-detail-value'}>Привезти заказ после 14:00</span>
						</div>
						<div className="i_order-item-source">
							<span>Источник: </span>
							<span className={'i_order-item-detail-value'}>Интернет-магазин</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Номер заказа источника: </span>
							<span className={'i_order-item-detail-value'}>339982</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Оплата: </span>
							<span className={'i_order-item-detail-value'}>Платежной картой Visa</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Доставка: </span>
							<span className={'i_order-item-detail-value'}>Самовывоз</span>
						</div>
					</div>
					<div className="i_orders-item-products">
						<div className="i_order-item-products-header">
							<div className="i_order-item-products-header-distributor">
								<span>Дистрибьютор</span>
							</div>
							<div className="i_order-item-products-header-name">
								<span>Название товара</span>
							</div>
							<div className="i_order-item-products-header-count">
								<span>Кол.</span>
							</div>
							<div className="i_order-item-products-header-price">
								<span>Цена</span>
							</div>
							<div className="i_order-item-products-header-sum">
								<span>Сумма</span>
							</div>
						</div>
						<div className="i_order-item-products-body">
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Процессор AMD Ryzen Threadripper 1900X WOF (BOX without fan) 8C/16T, 4.0Gh(Max), 180W, WOF</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>48 300 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>48 300 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 23,6" PHILIPS 243V5QHSBA/01 MVA FHD 8 мс 250кдм 3000:1 1xD-Sub 1xDVI 1xHDMI</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>52 884 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>52 884 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>4-портовый мини-разветвитель USB 2.0 Defender Quadro Light</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>2 170 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>2 170 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>CPU Intel Core i5-10500 3,1GHz (4,5GHz) 12Mb 6/12 Core Comet Lake Intel® UHD 630 65W FCLGA1200 Tray</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>69 382 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>69 382 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 27" PHILIPS 275E1S/01 IPS 2560x1440 75Hz 4ms 250cd/m 1000:1 1xDP 1xHDMI 1xD-SUB Black</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>86 332 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>86 332 KZT</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`i_orders-item show`}>
				<div className="i_orders-item-header">
					<div className="i_orders-item-id">
						<span>382</span>
					</div>
					<div className="i_orders-item-client">
						<span>Дусупов Руслан</span>
					</div>
					<div className="i_orders-item-date">
						<span>21.04.2024 13:30</span>
					</div>
					<div className="i_orders-item-status">
						<span>В обработке</span>
					</div>
					<div className="i_orders-item-distributors-count">
						<span>2 дистрибьютора</span>
					</div>
					<div className="i_orders-item-products-count">
						<span>5 товаров</span>
					</div>
					<div className="i_orders-item-summary">
						<span>259 068 KZT</span>
					</div>
				</div>
				<div className="i_orders-item-body">
					<div className="i_order-item-detail">
						<div className="i_order-item-client">
							<span>Клиент: </span>
							<span className={'i_order-item-detail-value'}>Дусупов Руслан</span>
						</div>
						<div className="i_order-item-email">
							<span>E-mail: </span>
							<span className={'i_order-item-detail-value'}>ruslan@mail.ru</span>
						</div>
						<div className="i_order-item-phone">
							<span>Телефон: </span>
							<span className={'i_order-item-detail-value'}>+7(777)324-93-08</span>
						</div>
						<div className="i_order-item-comments">
							<span>Комментарий: </span>
							<span className={'i_order-item-detail-value'}>Привезти заказ после 14:00</span>
						</div>
						<div className="i_order-item-source">
							<span>Источник: </span>
							<span className={'i_order-item-detail-value'}>Интернет-магазин</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Номер заказа источника: </span>
							<span className={'i_order-item-detail-value'}>339982</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Оплата: </span>
							<span className={'i_order-item-detail-value'}>Платежной картой Visa</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Доставка: </span>
							<span className={'i_order-item-detail-value'}>Самовывоз</span>
						</div>
					</div>
					<div className="i_orders-item-products">
						<div className="i_order-item-products-header">
							<div className="i_order-item-products-header-distributor">
								<span>Дистрибьютор</span>
							</div>
							<div className="i_order-item-products-header-name">
								<span>Название товара</span>
							</div>
							<div className="i_order-item-products-header-count">
								<span>Кол.</span>
							</div>
							<div className="i_order-item-products-header-price">
								<span>Цена</span>
							</div>
							<div className="i_order-item-products-header-sum">
								<span>Сумма</span>
							</div>
						</div>
						<div className="i_order-item-products-body">
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Процессор AMD Ryzen Threadripper 1900X WOF (BOX without fan) 8C/16T, 4.0Gh(Max), 180W, WOF</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>48 300 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>48 300 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 23,6" PHILIPS 243V5QHSBA/01 MVA FHD 8 мс 250кдм 3000:1 1xD-Sub 1xDVI 1xHDMI</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>52 884 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>52 884 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>4-портовый мини-разветвитель USB 2.0 Defender Quadro Light</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>2 170 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>2 170 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>CPU Intel Core i5-10500 3,1GHz (4,5GHz) 12Mb 6/12 Core Comet Lake Intel® UHD 630 65W FCLGA1200 Tray</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>69 382 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>69 382 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 27" PHILIPS 275E1S/01 IPS 2560x1440 75Hz 4ms 250cd/m 1000:1 1xDP 1xHDMI 1xD-SUB Black</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>86 332 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>86 332 KZT</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`i_orders-item hidden`}>
				<div className="i_orders-item-header">
					<div className="i_orders-item-id">
						<span>383</span>
					</div>
					<div className="i_orders-item-client">
						<span>Алина Полуэктова</span>
					</div>
					<div className="i_orders-item-date">
						<span>22.04.2024 10:30</span>
					</div>
					<div className="i_orders-item-status">
						<span>В обработке</span>
					</div>
					<div className="i_orders-item-distributors-count">
						<span>1 дистрибьютор</span>
					</div>
					<div className="i_orders-item-products-count">
						<span>2 товара</span>
					</div>
					<div className="i_orders-item-summary">
						<span>31 990 KZT</span>
					</div>
				</div>
				<div className="i_orders-item-body">
					<div className="i_order-item-detail">
						<div className="i_order-item-client">
							<span>Клиент: </span>
							<span className={'i_order-item-detail-value'}>Дусупов Руслан</span>
						</div>
						<div className="i_order-item-email">
							<span>E-mail: </span>
							<span className={'i_order-item-detail-value'}>ruslan@mail.ru</span>
						</div>
						<div className="i_order-item-phone">
							<span>Телефон: </span>
							<span className={'i_order-item-detail-value'}>+7(777)324-93-08</span>
						</div>
						<div className="i_order-item-comments">
							<span>Комментарий: </span>
							<span className={'i_order-item-detail-value'}>Привезти заказ после 14:00</span>
						</div>
						<div className="i_order-item-source">
							<span>Источник: </span>
							<span className={'i_order-item-detail-value'}>Интернет-магазин</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Номер заказа источника: </span>
							<span className={'i_order-item-detail-value'}>339982</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Оплата: </span>
							<span className={'i_order-item-detail-value'}>Платежной картой Visa</span>
						</div>
						<div className="i_order-item-source-id">
							<span>Доставка: </span>
							<span className={'i_order-item-detail-value'}>Самовывоз</span>
						</div>
					</div>
					<div className="i_orders-item-products">
						<div className="i_order-item-products-header">
							<div className="i_order-item-products-header-distributor">
								<span>Дистрибьютор</span>
							</div>
							<div className="i_order-item-products-header-name">
								<span>Название товара</span>
							</div>
							<div className="i_order-item-products-header-count">
								<span>Кол.</span>
							</div>
							<div className="i_order-item-products-header-price">
								<span>Цена</span>
							</div>
							<div className="i_order-item-products-header-sum">
								<span>Сумма</span>
							</div>
						</div>
						<div className="i_order-item-products-body">
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Процессор AMD Ryzen Threadripper 1900X WOF (BOX without fan) 8C/16T, 4.0Gh(Max), 180W, WOF</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>48 300 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>48 300 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Elco</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 23,6" PHILIPS 243V5QHSBA/01 MVA FHD 8 мс 250кдм 3000:1 1xD-Sub 1xDVI 1xHDMI</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>52 884 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>52 884 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>4-портовый мини-разветвитель USB 2.0 Defender Quadro Light</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>2 170 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>2 170 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>CPU Intel Core i5-10500 3,1GHz (4,5GHz) 12Mb 6/12 Core Comet Lake Intel® UHD 630 65W FCLGA1200 Tray</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>69 382 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>69 382 KZT</span>
								</div>
							</div>
							<div className="i_order-item-product">
								<div className="i_order-item-product-distributor">
									<span>Azerti</span>
								</div>
								<div className="i_order-item-product-name">
									<span>Монитор 27" PHILIPS 275E1S/01 IPS 2560x1440 75Hz 4ms 250cd/m 1000:1 1xDP 1xHDMI 1xD-SUB Black</span>
								</div>
								<div className="i_order-item-product-count">
									<span>1</span>
								</div>
								<div className="i_order-item-product-price">
									<span>86 332 KZT</span>
								</div>
								<div className="i_order-item-product-sum">
									<span>86 332 KZT</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrdersItems;