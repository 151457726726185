import Skeleton from "react-loading-skeleton";
import {Link, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";

import { api } from "../../../../api";
import '../styles/catalogPage.scss'
import infoImg from '../../../../images/info.svg'

import {useDispatch, useSelector} from "react-redux";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";


const CatalogDistributorPage = () => {
	const [isLoading, setIsLoading] = useState(true)

	const catalogList = useSelector(state => state.catalog.distributor.catalogList);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const distributorCatalogList = await api.cabinetApi.getDistributorCatalogList();
			dispatch({type: "REFRESH_CATALOG_LIST", catalogList: distributorCatalogList.catalogs})
			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	//Переход на страницу каталога
	const openCatalog = (code) => {
		navigate(`/catalogs/distributor/${code}/`);
	}

	useEffect(() => {
		getList()
	}, [])

	return (
		<div className={`i_catalog-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height: "100%"}}>
						<Skeleton
							count={10}
							height={'340px'}
							width={'19%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-skeleton'}
						/>
					</div>
				</>
			)}
			{!isLoading && catalogList.length > 0 && (
				<div className="i_catalog-items">
					{catalogList.map((item, index) => {
						return (
							<div className={'i_catalog-item'} key={index} onClick={()=>{openCatalog(`${item.distributor_id}/${item.code}`)}}>
								<div className="i_catalog-item-distributor-name">
									<span>{item.distributor_name}</span>
								</div>
								<div className="i_catalog-item-name">
									<Link to={`/catalogs/distributor/${item.distributor_id}/${item.code}/`} key={index}>
										<span>{item.name}</span>
									</Link>
								</div>
								<div className="i_catalog-item-code">
									<span>Код: {item.code}</span>
									<CopyToClipboardButton textToCopy={item.code} />
								</div>
								<div className="i_catalog-item-stats">
									<div className="i_catalog-item-category-active">
										<span>Категорий: {item.category}</span>
									</div>
									<div className="i_catalog-item-product-active">
										<span>Товаров: {item.product}</span>
									</div>
								</div>
								<div className="i_catalog-item-date">
									<span>Дата обновления  <br/>{item.date_update}</span>
								</div>
							</div>
						)
					})}
				</div>
			)}

			{!isLoading && catalogList.length === 0 && (
				<>
					<div className="i_catalog-page-empty">
						<div className="i_catalog-page-empty-title">
							<img src={infoImg} alt=""/>
							<span>Каталоги дистрибьюторов не подключены.</span>
						</div>

						<span>Чтобы подключить их, перейдите в раздел <Link to={'/distributors/'}>Дистрибьюторы</Link> и отправьте запрос на подключение нужному дистрибьютору из доступного списка</span>
					</div>
				</>
			)}

		</div>
	)
}

export default CatalogDistributorPage;