import { API_CABINET_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class AccountApi{

	//API проверяет авторизацию пользователя и отдает информацию о нем
	//Передаём пустой BODY
	
	getInfo(){
		return multiFetch(`${API_CABINET_URL}/account.info`, {}, {});
	}

}

