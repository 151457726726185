const DetailPrice = ({ price }) => {
	return (
		<div className="i_product-detail-price">
			<div className="i_product-detail-price-items">
				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Цена дилера</span>
					</div>
					<div className="i_product-detail-price-item-value">
			            <span>
			              {price && price.print_price ? (
				              <>
					              {price.print_price} {price.currency}
				              </>
			              ) : (
				              "Нет цены"
			              )}
			            </span>
					</div>
				</div>

				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Цена RRP</span>
					</div>
					<div className="i_product-detail-price-item-value">
			            <span>
			              {price && price.rrp ? (
				              <>
					              {price.print_rrp} {price.currency}
				              </>
			              ) : (
				              "Нет цены"
			              )}
			            </span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailPrice;
