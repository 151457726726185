import InputMask from "react-input-mask";
import {COUNTRY_CONFIG} from "../../../utils/config";
import {useSelector} from "react-redux";


//Кастомный инпут
const CustomInput = ({ title, value, onChange, isMobile, isBin, type, isTextarea, error}) => {

	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	// const siteID = 'kz'


	//Используем маску для мобильных устройств если isMobile = true, иначе обычный инпут
	return (
		<>
			<div className={'i_input'}>
				<label>{title}</label>
				{isMobile ? (
					<InputMask
						mask={COUNTRY_CONFIG[siteID].phone}
						maskchar={null}
						value={value}
						alwaysShowMask={false}
						formatchars={{ '9': '[0-9]' }}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					/>
				) : isBin ?
					<>
						<InputMask
							mask={COUNTRY_CONFIG[siteID].bin}
							maskchar={null}
							maskPlaceholder={null}
							value={value}
							alwaysShowMask={false}
							formatchars={{ '9': '[0-9]' }}
							onChange={(e) => {
								onChange(e.target.value);
							}}
						/>
					</>
					: type === 'number' ?
						<>
							<InputMask
								mask=""
								maskChar={null}
								value={value}
								alwaysShowMask={false}
								onChange={(e) => {
									const onlyNumbers = e.target.value.replace(/\D/g, ''); // Удаляем все нечисловые символы
									onChange(onlyNumbers);
								}}
								inputMode="numeric" // Опционально: для цифровой клавиатуры на мобильных устройствах
							/>
						</>
					: type === 'date' ?
						<>
							<InputMask
								mask="99.99.9999 99:99"
								placeholder="дд.мм.гггг чч:мм"
								value={value}
								onChange={(e) => onChange(e.target.value)}
							/>
						</>
					: isTextarea ?
						(
							<textarea value={value} onChange={(e) => onChange(e.target.value) }/>
						)
					:
					(
					<input type={type} value={value} onChange={(e) => onChange(e.target.value) }/>
				)}

				{
					error && (
						<div className="i_input-error">
							<span>{error}</span>
						</div>

					)
				}
			</div>
		</>
	);
};

export default CustomInput;
