import searchIcon from '../../../images/icon-search.svg';
import { useEffect, useRef, useState } from "react";
import { api } from "../../../api";
import {useDispatch, useSelector} from "react-redux";

const SearchBar = () => {
	// Состояния для управления значением ввода, таймаутом, результатами поиска и отображением результатов
	const [inputValue, setInputValue] = useState('');
	const [timeoutId, setTimeoutId] = useState(null);
	const [searchResult, setSearchResult] = useState(null);
	const [showResult, setShowResult] = useState(false);
	const searchBarRef = useRef(null); // реф для всего компонента search bar
	const searchBarInputRef = useRef(null); // реф для input элемента
	const dispatch = useDispatch();
	const role = useSelector(state => state.userData.data.entity.type);
	const modalIsOpen = useSelector(state => state.multiModal.modals[0].modalIsOpen);

	// Обработчик изменения ввода
	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);

		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Устанавливаем новый таймаут для задержки перед выполнением поиска
		const newTimeoutId = setTimeout(async () => {
			if (value.length > 0) {
				setShowResult(true);
				const searchResponse = await api.cabinetApi.searchTitle({ query: value });

				if (searchResponse.items?.length > 0) {
					setSearchResult(searchResponse.items);
				} else {
					setSearchResult(null);
				}
			} else {
				setShowResult(false);
			}
		}, 500);

		setTimeoutId(newTimeoutId);
	};

	// Открытие результатов поиска при фокусе на input
	const openSearchResult = () => {
		if (inputValue.length > 0) {
			setShowResult(true);
		} else {
			setShowResult(false);
		}
	};

	// useEffect для обработки клика за пределами компонента
	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (searchBarRef.current && !searchBarRef.current.contains(event.target) && !event.target.closest('.i_multi_modal')) {
				setShowResult(false);
			} else {
				if (inputValue.length > 0) {
					// setShowResult(true);
				}
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [inputValue]);

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		setTimeout(() => {
			if (event.key === 'Escape') {
				setShowResult(false);
				searchBarInputRef.current.blur();
			}
		}, 100);
	};

	// useEffect для добавления и удаления обработчика нажатия клавиш
	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	//Функция для открытия модального окна с информацией о товаре
	const openCatalogDetailModal = (id, type) => {
		if(role === 'distributor'){
			dispatch({type: "OPEN_MODAL", modalType: "distributorCatalogProductDetailModal", modalLevel: 1, modalData: {productId : id}, modalWidth: 'large'})
		}else{
			if(type === 'distributor'){
				dispatch({type: "OPEN_MODAL", modalType: "dealerCatalogProductDetailModal", modalLevel: 1, modalData: {productId : id}, modalWidth: 'large'})
			}else{
				dispatch({type: "OPEN_MODAL", modalType: "sourceCatalogProductDetailModal", modalLevel: 1, modalData: {productId : id}, modalWidth: 'large'})
			}
		}
	}

	return (
		<div className="i_search-bar" ref={searchBarRef}>
			<div className="i_search-bar-input">
				<input
					type="text"
					placeholder="Поиск по названию..."
					value={inputValue}
					onChange={handleInputChange}
					onFocus={openSearchResult}
					ref={searchBarInputRef}
				/>
			</div>
			<div className="i_search-bar-btn">
				<img src={`${searchIcon}`} alt="icon" />
			</div>

			<div className={`i_search-bar-items ${showResult ? 'show' : ''}`}>
				<div className="i_search-bar-container">
					{searchResult && searchResult.map((item, index) => (
						<div className="i_search-bar-item" key={index} onClick={()=>{
							openCatalogDetailModal(item.id, item.type)
						}}>
							<div className="i_search-bar-item-name">
								<span>{item.name}</span>
							</div>
							<div className="i_search-bar-item-meta">
								<span>{item.meta.source}</span>
							</div>
						</div>
					))}

					{!searchResult && (
						<div className="i_search-bar-item-empty">
							<span>Ничего не найдено</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default SearchBar;
