import styles from './ScrollBox.module.scss'

const ScrollBox = ({ children, height = '100%', width = '100%' }) => {
	return (
		<div className={styles.scrollBox} style={{ height, width }}>
			{children}
		</div>
	);
};

export default ScrollBox;