import {useState} from "react";

const SwitchButton = ({value, checkBoxValue ,inputChecked, title}) => {

	const [isChecked, setIsChecked] = useState(inputChecked);

	const handleInputChange = () => {
		// Обновляем состояние isChecked при изменении чекбокса
		const newCheckedValue = !isChecked;
		setIsChecked(newCheckedValue);

		console.log(123)


		// Если новое значение isChecked равно true, выводим значение в консоль
		checkBoxValue({value:value, isChecked: newCheckedValue});
	};


	return(
		<div className={'i_switch'}>
			<div className="switch">
				<input type="checkbox"
				       checked={isChecked}
				       onChange={handleInputChange}
				       value={value}
				/>
				<span className="slider round"></span>
			</div>
			{title && (
				<div className="i_switch-title">
					<span>{title}</span>
				</div>

			)}
		</div>
	)
}

export default SwitchButton;