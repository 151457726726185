import {useSelector} from "react-redux";
import EditMarkupElement from "../multitools/components/EditMarkupElement";


const EditElementModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)

	return (
		<>
			{currentTools === "markups-my-catalog" && <EditMarkupElement/>}
		</>
	);
}

export default EditElementModal