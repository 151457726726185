import {ProductListHead, ProductListItem} from "../../../../../utils/ui/";
import {useDispatch} from "react-redux";
import styles from './SourceList.module.scss';

const SourceList = ({store}) => {

	const dispatch = useDispatch();

	const openCatalogDetailModal = (id) => {
		dispatch({
			type: "OPEN_MODAL",
			modalType: "sourceCatalogProductDetailModal",
			modalLevel: 1,
			modalData: {productId : id},
			modalWidth: 'large'
		});
	};

	return (
		<div className={styles.productBlock}>
			<ProductListHead
				columns={store.list.columns}
				store={store}
				actionType={'catalogs'}
				showCheckBox={true}
			/>
			<ProductListItem
				products={store.list.products.productList}
				columns={store.list.columns}
				store={store}
				emptyTitle={'Товары отсутствуют.'}
				showCheckBox={true}
				actionType={'catalogs'}
				catalogType={'source'}
				itemClick={openCatalogDetailModal}
			/>
		</div>
	)
}

export default SourceList;