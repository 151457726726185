import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";
import getDeclension from "../utils/getDeclension";

const DeleteSourceElement = () => {
	const dispatch = useDispatch();
	const store = useSelector(store => store.catalog);
	const quantity = store?.list.products.checkedProductId.length;
	const wordForms = ['товар', 'товара', 'товаров'];

	const deleteElement = async () => {
		try{
			const response = await api.cabinetApi.deleteSourceProducts({id: store?.list.products.checkedProductId});
			if(response.status === true){
				const updatedList = store?.list.products.productList.filter(item => !store?.list.products.checkedProductId.includes(item.id));

				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedList})
				dispatch({type: "SET_CATALOG_SELECTED_PRODUCT_ID", checkedProductId: []})
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: response.message, notificationType: 'success'})
			}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>Вы действительно хотите удалить {quantity} {getDeclension(quantity, wordForms)}?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={deleteElement}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteSourceElement;