import React, { useState, useRef } from 'react';

import copySvg from '../../images/copy-clipboard.svg'
import successSvg from '../../images/success.svg'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

const CopyToClipboardButton = ({ textToCopy }) => {
	const {t} = useTranslation();
	const [isCopied, setIsCopied] = useState(false);
	const textAreaRef = useRef(null);
	const dispatch = useDispatch();

	const handleCopyClick = (event) => {
		event.stopPropagation()
		textAreaRef.current.select();
		document.execCommand('copy');
		setIsCopied(true);

		dispatch({type: "SHOW_NOTIFICATION", notificationIsOpen: true, notificationContent: t('I_CLIPBOARD_SUCCESS'), notificationType: 'success'})

		setTimeout(() =>{
			setIsCopied(false);
		}, 3000)
	};

	return (
		<>
			<div className={'i_clipboard'}>
				<button onClick={(event)=>{handleCopyClick(event)}} disabled={isCopied}>
					<img src={isCopied ? successSvg : copySvg} alt=""/>
				</button>
				<textarea
					ref={textAreaRef}
					value={textToCopy}
					readOnly
					style={{ position: 'absolute', left: '-9999px' }}
				/>
			</div>
		</>

	);
};

export default CopyToClipboardButton;
